import React, { useState } from 'react'
import PageTitle from "../components/PageTitle/PageTitle";
import { Button, Grid } from "@material-ui/core";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";

function Sitesetting() {

    let history = useHistory();

    const siteList = {
        SiteName: '',
        Status: ''
    }

    const [siteData, setsiteData] = useState(siteList)
    const [sitelogo, setsitelogo] = useState("Sitelogo")
    const [favicon, setfavicon] = useState("Favicon")
    const [favimage, setfavimage] = useState("")
    const [logoimage, setlogoimage] = useState("")


    const getValue = (e) => {
        // if (e && e.preventDefault) { e.preventDefault(); }
        const { name, value } = e.target;
        let formData = { ...siteData, ...{ [name]: value } };
        setsiteData(formData);
        console.log(siteData, "=-=-=-===-=-")
    }

    const siteLogo = (val) => {
        console.log("val===", val)
        const fileExtension = val.name.split(".").at(-1);
        const fileSize = val.size;
        const fileName = val.name;
        console.log("fileExtension===", fileExtension);
        console.log("fileSize===", fileSize);
        console.log("fileName===", fileName);
        if (fileExtension != "png" && fileExtension != "jpg" && fileExtension != "jpeg") {
            // toast.error("File does not support. You must use .png or .jpg or .jpeg ");
            return false;
        }
        else if (fileSize > 1000000) {
            //   toast.error("Please upload a file smaller than 1 MB");
            return false;
        }
        else {
            const data = new FormData()
            data.append("file", val)
            data.append("upload_preset", "ebjirh4i")
            data.append("cloud_name", "blf")
            console.log("formdata===", data)
            fetch("  https://api.cloudinary.com/v1_1/blf/auto/upload", {
                method: "post",
                body: data
            })
                .then(resp => resp.json())
                .then(data => {
                    console.log("cloudinary upload===", data);
                    setsitelogo(data.secure_url);
                    setlogoimage(fileName);
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    const favIcon = (val) => {
        console.log("val===", val)
        const fileExtension = val.name.split(".").at(-1);
        const fileSize = val.size;
        const fileName = val.name;
        console.log("fileExtension===", fileExtension);
        console.log("fileSize===", fileSize);
        console.log("fileName===", fileName);
        if (fileExtension != "png" && fileExtension != "jpg" && fileExtension != "jpeg") {
            // toast.error("File does not support. You must use .png or .jpg or .jpeg ");
            return false;
        }
        else if (fileSize > 1000000) {
            //   toast.error("Please upload a file smaller than 1 MB");
            return false;
        }
        else {
            const data = new FormData()
            data.append("file", val)
            data.append("upload_preset", "ebjirh4i")
            data.append("cloud_name", "blf")
            console.log("formdata===", data)
            fetch("  https://api.cloudinary.com/v1_1/blf/auto/upload", {
                method: "post",
                body: data
            })
                .then(resp => resp.json())
                .then(data => {
                    console.log("cloudinary upload===", data);
                    setfavicon(data.secure_url);
                    setfavimage(fileName);
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    const formSubmit = async (e) => {
        if (e && e.preventDefault) { e.preventDefault(); }
        if (favimage == "") {
            console.log("error")
        } else {
            siteData["Favicon"] = favimage
            console.log("favicon add success")
        }
        if (logoimage == "") {
            console.log("error")
        } else {
            siteData["Sitelogo"] = logoimage
            console.log("logo add success")
        }

        var data = {
            apiUrl: apiService.Sitesetting,
            payload: siteData,
        };
        console.log(data, "=-==-data=-=-=-");
        var resp = await postMethod(data);
        if (resp.Status == true) {
            toast(resp.Message)
            history.push('/app/dashboard')
        }
    }
    return (
        <>
            <PageTitle title="Site Setting" />
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <div className="card">
                        <Grid container spacing={4} justify="center">
                            <Grid item xs={5}>
                                <form className="form_padding">
                                    <div className="custom-file">
                                        <label>Favicon</label>
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            id="customFile"
                                            onChange={(e) => favIcon(e.target.files[0])}

                                        />
                                        <label className="custom-file-label" htmlFor="customFile">
                                            {favicon}
                                        </label>
                                    </div>
                                    <div className="form-group">
                                        <label><b>Site Name</b></label>
                                        <input
                                            name='SiteName'
                                            className="form-control"
                                            placeholder="Site Name"
                                            onChange={getValue}
                                        />
                                    </div>

                                    <div className="custom-file">
                                        <label>Site Logo</label>
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            id="customFile"
                                            onChange={(e) => siteLogo(e.target.files[0])}
                                        />
                                        <label className="custom-file-label" htmlFor="customFile">
                                            {sitelogo}
                                        </label>
                                    </div>
                                    <div>
                                        <label><b>Under Maintenance</b></label>
                                        <div>
                                            <label>Active</label>
                                            <input type="radio" name="Status" onChange={getValue} value="Active"></input>
                                            <label>Deactive</label>
                                            <input type="radio" name="Status" onChange={getValue} value="Deactive"></input>
                                        </div>
                                    </div>
                                    <button className="btn btn-primary custom_btn" onClick={formSubmit}>Submit</button>
                                </form>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12}></Grid>
            </Grid>
        </>
    )
}

export default Sitesetting;