import React, { useState } from 'react'
import PageTitle from "../components/PageTitle/PageTitle";
import { Button, Grid } from "@material-ui/core";
import { postMethod } from '../core/service/common.api';
import apiService from "../core/service/detail";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";



function Changepasswd() {

  let history = useHistory();

  const values = {
    Oldpasswd: "",
    Newpasswd: "",
    Confirmpasswd: ""
  }

  const [passwordData, setpasswordData] = useState(values)
  const [OldpasswdE, setOldpasswdE] = useState(false)
  const [NewpasswdE, setNewpasswdE] = useState(false)
  const [ConfirmpasswdE, setConfirmpasswdE] = useState(false)
  const [validationnErr, setvalidationnErr] = useState("")

  const [oldpasshd, setoldpasshd] = useState(false)
  const [newpasshd, setnewpasshd] = useState(false)
  const [confirmpasshd, setconfirmpasshd] = useState(false)

  const [newinputType, setnewinputType] = useState("password")
  const [oldinputType, setoldinputType] = useState("password")
  const [confirminputType, setconfirminputType] = useState("password")



  const getValues = (e) => {
    if (e && e.preventDefault) { e.preventDefault(); }
    const { name, value } = e.target;
    let formData = { ...passwordData, ...{ [name]: value } };
    setpasswordData(formData);
  }

  const validation = async () => {
    const err = {}
    if (!passwordData.Oldpasswd) {
      err.Oldpasswd = "Old password is required"
      setOldpasswdE(true)
    } else if (!passwordData.Oldpasswd.match(/[a-z]/g)) {
      err.Oldpasswd = "Please enter Lowercase"
      setOldpasswdE(true)
    } else if (!passwordData.Oldpasswd.match(/[A-Z]/g)) {
      err.Oldpasswd = "Please enter Uppercase"
      setOldpasswdE(true)
    }
    else if (!passwordData.Oldpasswd.match(/[0-9]/g)) {
      err.Oldpasswd = "Please enter Numbers"
      setOldpasswdE(true)
    } else if (passwordData.Oldpasswd.length < 6 || passwordData.Oldpasswd.length > 30) {
      err.Oldpasswd = "Please enter 6 to 30"
      setOldpasswdE(true)
    }

    if (!passwordData.Newpasswd) {
      err.Newpasswd = "Old password is required"
      setNewpasswdE(true)
    } else if (!passwordData.Newpasswd.match(/[a-z]/g)) {
      err.Newpasswd = "Please enter Lowercase"
      setOldpasswdE(true)
    } else if (!passwordData.Newpasswd.match(/[A-Z]/g)) {
      err.Newpasswd = "Please enter Uppercase"
      setOldpasswdE(true)
    }
    else if (!passwordData.Newpasswd.match(/[0-9]/g)) {
      err.Newpasswd = "Please enter Numbers"
      setOldpasswdE(true)
    } else if (passwordData.Newpasswd.length < 6 || passwordData.Newpasswd.length > 30) {
      err.Newpasswd = "Please enter 6 to 30"
      setNewpasswdE(true)
    }

    if (passwordData.Oldpasswd === passwordData.Newpasswd) {
      err.Newpasswd = "Old and New password match"
      setNewpasswdE(true)
    }

    if (passwordData.Newpasswd != passwordData.Confirmpasswd) {
      err.Confirmpasswd = "New and Confirm password not match"
      setConfirmpasswdE(true)
    }


    setvalidationnErr(err)
  }

  const fromSubmit = async (e) => {
    if (e && e.preventDefault) { e.preventDefault(); }
    validation(passwordData)
    if (
      passwordData.Oldpasswd != "" && passwordData.Oldpasswd.length > 5 && passwordData.Oldpasswd.length < 31 &&
      passwordData.Newpasswd != "" && passwordData.Newpasswd.length > 5 && passwordData.Newpasswd.length < 31 &&
      passwordData.Confirmpasswd === passwordData.Newpasswd &&
      passwordData.Oldpasswd != passwordData.Newpasswd
    ) {
      var ChangePasswd = {
        apiUrl: apiService.ChangePassword,
        payload: passwordData
      };
      console.log(ChangePasswd, "=-=-=-ChangePasswd==-=-=")
      var responce = await postMethod(ChangePasswd)

      if (responce.Status == true) {
        toast(responce.Message)
        history.push('/login')
      }else{
        toast(responce.Message)
      }
    } else {
      console.log("not api call")
    }

  }

  const oldPasseye = (data) => {
    if (data == "hide") {
      setoldpasshd(true);
      setoldinputType("text");
    } else {
      setoldpasshd(false);
      setoldinputType("password");
    }
  }

  const newPasseye = (data) => {
    if (data == "hide") {
      setnewpasshd(true);
      setnewinputType("text");
    } else {
      setnewpasshd(false);
      setnewinputType("password");
    }
  }

  const confirmPasseye = (data) => {
    if (data == "hide") {
      setconfirmpasshd(true);
      setconfirminputType("text");
    } else {
      setconfirmpasshd(false);
      setconfirminputType("password");
    }
  }


  return (
    <>
      <PageTitle title="Change Password" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <div className="card">
            <Grid container spacing={4} justify="center">
              <Grid item xs={5}>
                <form className="form_padding">
                  <div className="form-group">
                    <label>Old Password</label>
                    <input
                      type={oldinputType}
                      name='Oldpasswd'
                      className="form-control"
                      placeholder="Old Password"
                      onChange={getValues}
                    />
                    {OldpasswdE == true ? (<p className="text-danger">{" "}{validationnErr.Oldpasswd}{" "}</p>) : ("")}
                    {oldpasshd == false ? (
                      <i
                        className="bi bi-eye-slash-fill"
                        onClick={() => oldPasseye("hide")}
                      ></i>
                    ) : (
                      <i
                        className="bi bi-eye-fill"
                        onClick={() => oldPasseye("show")}
                      ></i>
                    )}
                  </div>
                  <div className="form-group">
                    <label>New Password</label>
                    <input
                      type={newinputType}
                      name='Newpasswd'
                      className="form-control"
                      placeholder="New Password"
                      onChange={getValues}
                    />
                    {NewpasswdE == true ? (<p className="text-danger">{" "}{validationnErr.Newpasswd}{" "}</p>) : ("")}
                    {newpasshd == false ? (
                      <i
                        className="bi bi-eye-slash-fill"
                        onClick={() => newPasseye("hide")}
                      ></i>
                    ) : (
                      <i
                        className="bi bi-eye-fill"
                        onClick={() => newPasseye("show")}
                      ></i>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Confirm Password</label>
                    <input
                      type={confirminputType}
                      name='Confirmpasswd'
                      className="form-control"
                      placeholder="Confirm Password"
                      onChange={getValues}
                    />
                    {ConfirmpasswdE == true ? (<p className="text-danger">{" "}{validationnErr.Confirmpasswd}{" "}</p>) : ("")}
                    {confirmpasshd == false ? (
                      <i
                        className="bi bi-eye-slash-fill"
                        onClick={() => confirmPasseye("hide")}
                      ></i>
                    ) : (
                      <i
                        className="bi bi-eye-fill"
                        onClick={() => confirmPasseye("show")}
                      ></i>
                    )}
                  </div>
                  <button className="btn btn-primary custom_btn" onClick={fromSubmit}>Submit</button>
                </form>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </>
  )
}

export default Changepasswd;