import React, { useEffect, useState } from 'react'
import PageTitle from "../components/PageTitle";
import apiService from "../core/service/detail";
import { postMethod } from "../core/service/common.api";
import Pagination from "react-js-pagination";
import moment from 'moment';


function Subscribe() {

    const [viewData, setviewData] = useState([])
    const [currentPage, setcurrentPage] = useState(1)
    const [totalPage, settotalPage] = useState(0)
    const [BlocksentelsviewData, setBlocksentelsviewData] = useState([])
    const [BlocksentelscurrentPage, setBlocksentelscurrentPage] = useState(1)
    const [BlocksentelstotalPage, setBlocksentelstotalPage] = useState(0)

    const recordPerPage = 5;
    const totalRecords = 15;
    const pageRange = 5;


    const BlocksentelsrecordPerPage = 5;
    const BlocksentelstotalRecords = 15;
    const BlocksentelspageRange = 5;

    const handlePageChange = pageNumber => {
        getData(pageNumber)
        setcurrentPage(pageNumber);
        // setchoosepage()
    }
     const BlocksentelshandlePageChange = pageNumber => {
        getBlocksentelsData(pageNumber)
        setBlocksentelscurrentPage(pageNumber);
        // setchoosepage()
    }

    useEffect(() => {
        getData();
        getBlocksentelsData();
    }, []);
 
    const getBlocksentelsData = async (page) => {
        var payload = {
            perpage: 5,
            page: page
          }
        var list = {
            apiUrl: apiService.getBlocksentelSubscribelist,
            payload : payload
        };
        var resp = await postMethod(list);
        console.log(resp.data)
        setBlocksentelsviewData(resp.data.data)
        setBlocksentelstotalPage(resp.data.total)


    };
    const getData = async (page) => {
        var payload = {
            perpage: 5,
            page: page
          }
        var list = {
            apiUrl: apiService.Subscribelist,
            payload : payload
        };
        var resp = await postMethod(list);
        console.log(resp.data)
        setviewData(resp.data.data)
        settotalPage(resp.data.total)


    };
    return (
        <>
            <PageTitle title=" Beleaf Subscribe List" />
            <div>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th> S.No</th>
                            <th> Email </th>
                            <th> Date </th>
                        </tr>
                    </thead>
                    <tbody>
                        {viewData.map((data, i) => {
                            return (
                                <tr>
                                    <td>{i + 1}</td>
                                    <td>{data.email}</td>
                                    <td>{moment(data.createdAt).format("lll")}</td>
                                </tr>
                            )
                        })}

                    </tbody>

                </table>

                <Pagination
                    itemClass="page-item" // add it for bootstrap 4
                    linkClass="page-link" // add it for bootstrap 4
                    activePage={currentPage}
                    itemsCountPerPage={recordPerPage}
                    totalItemsCount={totalPage}
                    pageRangeDisplayed={pageRange}
                    onChange={handlePageChange}
                />
            </div>

            <PageTitle title=" Blocksentels Subscribe List" />
            <div>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th> S.No</th>
                            <th> Email </th>
                            <th> Date </th>
                        </tr>
                    </thead>
                    <tbody>
                        {BlocksentelsviewData.map((data, i) => {
                            return (
                                <tr>
                                    <td>{i + 1}</td>
                                    <td>{data.email}</td>
                                    <td>{moment(data.createdAt).format("lll")}</td>
                                </tr>
                            )
                        })}

                    </tbody>

                </table>

                <Pagination
                    itemClass="page-item" // add it for bootstrap 4
                    linkClass="page-link" // add it for bootstrap 4
                    activePage={BlocksentelscurrentPage}
                    itemsCountPerPage={BlocksentelsrecordPerPage}
                    totalItemsCount={BlocksentelstotalPage}
                    pageRangeDisplayed={BlocksentelspageRange}
                    onChange={BlocksentelshandlePageChange}
                />
            </div>
        </>
    )
}

export default Subscribe