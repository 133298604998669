import React from "react";
import {toast} from "react-toastify";
import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";

export{uploadBook};


async function uploadBook(bookTitle,bookDescription,coverImage,bookPdf){
    // setError(false);
    // setIsLoading(true);
    if(bookTitle !="" && bookDescription !="" && bookPdf!="" && coverImage!=""){
        var data={
            apiUrl:apiService.uploadBook,
            payload:{
                bookTitle:bookTitle,
                bookDescription:bookDescription,
                coverImage:coverImage,
                bookPdf:bookPdf

            }
        }
        console.log(data)
       var resp=await postMethod(data)
       if(resp){
              
       }
    }
}