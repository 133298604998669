import React, { useEffect } from "react";

import useState from "react-usestateref";

import Pagination from "react-js-pagination";
import apiService from "../core/service/detail";
import { getMethod } from "../core/service/common.api";
import { postMethod } from "../core/service/common.api";
import moment from "moment";

function BlocksentialContact() {
  const [currentPage, setCurrentPage] = useState(1);
  const [getP2POrders, setgetAllp2pOrders, getP2POrdersref] = useState([]);
  const [totalPage, setTotalpages] = useState(0);

  const recordPerPage = 5;
  // total number of the records
  const totalRecords = 15;
  // range of pages in paginator
  const pageRange = 5;

  const handlePageChange = (pageNumber) => {
    getContacts(pageNumber);
    setCurrentPage(pageNumber);
    // setchoosepage()
  };

  useEffect(() => {
    getContacts();
  }, []);

  const getContacts = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page,
        search: "btc",
      };
      var data = {
        apiUrl: apiService.getBlocksentialscontacts,
        payload: payload,
      };
      console.log(data);
      var resp = await postMethod(data);

      if (resp.status) {
        console.log(resp.data.data, "resp.data.data");
        setgetAllp2pOrders(resp.data.data);
        setTotalpages(resp.data.total);
      }
    } catch (error) {}
  };

  const enquiry = async (e) => {
    try {
      var payload = {
        _id: e._id,
        status: "read",
      };
      var data = {
        apiUrl: apiService.changestatus,
        payload: payload,
      };

      var resp = await postMethod(data);
      if (resp.status) {
        getContacts();
      } else {
      }
    } catch (error) {
      console.log("=-=-=-=-=-=-=-=-", error, "=-=-=-=-=-error-=-=");
    }
  };

  return (
    <div>
      Block sentinels Contact
      <table class="table table-striped">
        <thead>
          <tr>
            <th> S.No</th>
            <th> Name </th>
            <th> Email</th>
            <th> Mobile Number</th>
            <th>  Country</th>
            <th> Message </th>
            <th> recivedDate </th>
            <th> Status </th>
          </tr>
        </thead>

        <tbody>
          {getP2POrdersref.current &&
            getP2POrdersref.current.map((orders, i) => {
              return (
                <tr>
                  <td>{i + 1}</td>
                  <td>{orders.userName}</td>
                  <td>{orders.email}</td>
                  <td>{orders.mobileNumber}</td>
                  <td>{orders.country}</td>
                  <td>{orders.comments}</td>

                  <td>{moment(orders.createdAt).format("lll")}</td>

                  <td>
                    {orders.readStatus == "notread" ? (
                      <p
                        style={{ color: "green" }}
                        onClick={() => enquiry(orders)}
                      >
                        {" "}
                        Open{" "}
                      </p>
                    ) : (
                      <p style={{ color: "red" }}> Closed </p>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <Pagination
        itemClass="page-item" // add it for bootstrap 4
        linkClass="page-link" // add it for bootstrap 4
        activePage={currentPage}
        itemsCountPerPage={recordPerPage}
        totalItemsCount={totalPage}
        pageRangeDisplayed={pageRange}
        onChange={handlePageChange}
      />
    </div>
  );
}

export default BlocksentialContact;
