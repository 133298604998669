import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";
import Banersection from "../../pages/Banersection"
// styles
import useStyles from "./styles";
import Subscribe from "../../pages/Subscribe";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";

// pages
import Dashboard from "../../pages/dashboard";
import Typography from "../../pages/typography";
import Notifications from "../../pages/notifications";
import Maps from "../../pages/maps";
import Tables from "../../pages/tables";
import Icons from "../../pages/icons";
import Changepasswd from "../../pages/Changepasswd";
import Charts from "../../pages/charts";
import BlogCategory from "../../pages/BlogCategory";
import BlogUpload from "../../pages/BlogUpload";
import PageMeta from "../../pages/PageMeta";
import Enquiry from "../../pages/Enquiry";
import Contact from "../../pages/contact";
import Career from "../../pages/Career";
import ServiceList from "../../pages/serviceList"
import Sitesetting from "../../pages/Sitesetting";
import coursecontact from"../../pages/coursecontactlist";
import Book from"../../pages/book";
import Downloaderlist from"../../pages/Bookdownloaderlist";
import BlocksentialContact from "../../pages/blocksentialcontacts"
import Employeedata from "../../pages/employeedata"
import Job from "../../pages/job"
import Internshipers from "../../pages/internshipers"
import Beleafblogcontact from "../../pages/beleafblogcontact"
import Blocksentinalsblogcontact from "../../pages/blocksentialsblogcontact"
import Jobapplyerlist from "../../pages/jobapplyerlist"
import Internapplyerlist from "../../pages/internapplyerlist"
// context
import { useLayoutState } from "../../context/LayoutContext";
function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route path="/app/dashboard" component={Dashboard} />
            <Route path="/app/typography" component={Typography} />
            <Route path="/app/Changepasswd" component={Changepasswd} />
            <Route path="/app/tables" component={Tables} />
            <Route path="/app/blog_catagory" component={BlogCategory} />
            <Route path="/app/blog_upload" component={BlogUpload} />
            <Route path="/app/page_meta" component={PageMeta} />
            <Route path="/app/enquiry" component={Enquiry} />
            <Route path="/app/service_list" component={ ServiceList } />
            <Route path="/app/notifications" component={Notifications} />
            <Route path="/app/Banersection" component={Banersection} />
            <Route path="/app/Sitesetting" component={Sitesetting} />
            <Route path="/app/Subscribe" component={Subscribe} />
            <Route path="/app/book" component={Book} />
            <Route path="/app/blocksentialcontacts" component={BlocksentialContact} />
            <Route path="/app/Bookdownloaderlist" component={Downloaderlist} />
            <Route path="/app/employeedata" component={Employeedata} />
            <Route path="/app/job" component={Job} />
            <Route path="/app/internshipers" component={Internshipers} />
            <Route path="/app/beleafblogcontact" component={Beleafblogcontact} />
            <Route path="/app/blocksentinalsblogcontact" component={Blocksentinalsblogcontact} />
            <Route path="/app/jobapplyerlist" component={Jobapplyerlist} />
            <Route path="/app/internapplyerlist" component={Internapplyerlist} />
            
            <Route
              exact
              path="/app/ui"
              render={() => <Redirect to="/app/ui/icons" />}
            />
            <Route path="/app/ui/maps" component={Maps} />
            <Route path="/app/ui/icons" component={Icons} />
            <Route path="/app/ui/charts" component={Charts} />
            <Route path='/app/contact'  component={Contact}></Route>
            <Route path='/app/career'  component={Career}></Route>
            <Route path='/app/coursecontact'  component={coursecontact}></Route>

          </Switch>
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
