import React, { useEffect } from "react";
import useState from "react-usestateref";
import PageTitle from "../components/PageTitle/PageTitle";
import { Grid } from "@material-ui/core";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod, getMethod } from "../core/service/common.api";
import Pagination from "react-js-pagination";
import moment from "moment";
import CKEditor from "react-ckeditor-component";

function Job() {
  //Job status
  const [jobpoststatus, setjobpoststatus] = useState("Active");
  const [loader, setLoader] = useState(false);
  const poststatus = (e) => {
    setjobpoststatus(e.target.value);
  };

  //CLEDITOR

  const [state, setState] = useState({
    content: "",
  });
  const updateContent = (newContent) => {
    setState({
      content: newContent,
    });
  };

  const onChange = (evt) => {
    console.log("onChange fired with event info: ", evt);
    var newContent = evt.editor.getData();
    setState({
      content: newContent,
    });
  };

  const onBlur = (evt) => {
    console.log("onBlur event called with event info: ", evt);
  };

  const afterPaste = (evt) => {
    console.log("afterPaste event called with event info: ", evt);
  };

  //BANNER UPLOAD

  const [jobbanner, setJobBanner] = useState();
  var jobBannerUpload = (val) => {
    try {
      console.log("fileExtension===", val.name);

      const fileExtension = val.name.split(".").at(-1);
      const fileSize = val.size;
      const fileName = val.name;
      console.log("fileExtension===", fileExtension);
      console.log("fileSize===", fileSize);
      console.log("fileName===", fileName);
      if (
        fileExtension != "png" &&
        fileExtension != "jpg" &&
        fileExtension != "jpeg" &&
        fileExtension != "webp"
      ) {
        toast.error(
          "File does not support. You must use .png or .jpg or .jpeg ",
        );
        return false;
      } else if (fileSize > 1000000) {
        toast.error("Please upload a file smaller than 1 MB");
        return false;
      } else {
        const data = new FormData();
        data.append("file", val);
        data.append("upload_preset", "ebjirh4i");
        data.append("cloud_name", "blf");
        console.log("formdata===", data);
        fetch("  https://api.cloudinary.com/v1_1/blf/auto/upload", {
          method: "post",
          body: data,
        })
          .then((resp) => resp.json())
          .then((data) => {
            console.log("cloudinary upload===", data);
            setJobBanner(data.secure_url);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      console.log(err, "erro--=--=-=-=-=-=");
    }
  };

  var initialvalues = {
    jobDepartment: "",
    jobtiming: "",
    jobPosition: "",
    jobDescription: "",
    location: "",
    skills: "",
  };

  var [inputvalues, setInputvalues] = useState(initialvalues);

  const {
    jobDepartment,
    jobtiming,
    jobPosition,
    jobDescription,
    location,
    skills,
  } = inputvalues;

  useEffect(() => {
    get_job_Details();
  }, []);
  var [Slug, setSlug, Slugref] = useState("");

  var handleChange = (e) => {
    try {
      e.preventDefault();
      const { name, value } = e.target;
      let datas = { ...inputvalues, ...{ [name]: value } };
      setInputvalues(datas);

      var slug = datas.jobPosition
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
      setSlug(slug);
    } catch (err) {
      console.log(err, "erro--=--=-=-=-=-=");
    }
  };

  var [departmentErr, setdepartmentErr, departmentErrref] = useState(false);
  var [jobtimingErr, setjobimingErr, jobtimingErrref] = useState(false);
  var [jobPositionErr, setjobPositionErr, jobPositionErrref] = useState(false);
  var [locationErr, setlocationErr, locationErrref] = useState(false);
  var [DescriptionErr, setDescriptionErr, DescriptionErrref] = useState(false);
  var [skillsErr, setSkillsErr, skillsErrref] = useState(false);
  var [bannerErr, setBannerErr, bannerErrref] = useState(false);
  var [validate, setvalidate] = useState(false);

  const vallidation = (inputvalues) => {
    try {
      var error = {};
      if (inputvalues.jobDepartment == "") {
        setdepartmentErr(true);
        error.jobDepartment = "Job Department can't be empty";
      } else {
        setdepartmentErr(false);
      }
      if (inputvalues.jobtiming == "") {
        setjobimingErr(true);
        error.jobtiming = "Job Timing can't be empty";
      } else {
        setjobimingErr(false);
      }
      if (inputvalues.jobPosition == "") {
        setjobPositionErr(true);
        error.jobPosition = " Position can't be empty";
      } else {
        setjobPositionErr(false);
      }
      if (inputvalues.jobDescription == "") {
        setDescriptionErr(true);
        error.jobDescription = " Description can't be empty";
      } else {
        setDescriptionErr(false);
      }
      if (inputvalues.location == "") {
        setlocationErr(true);
        error.location = "Location can't be empty";
      } else {
        setlocationErr(false);
      }
      if (inputvalues.skills == "") {
        setSkillsErr(true);
        error.skills = "Skill Details can't be empty";
      } else {
        setSkillsErr(false);
      }
      if (jobbanner == "" || jobbanner == null || jobbanner == undefined) {
        error.jobbanner = " Intern Banner can't be empty";
        setBannerErr(true);
      } else {
        setBannerErr(false);
      }

      setvalidate(error);
    } catch (err) {
      console.log(err, "err=-=-=-=-=-=-=-=-=-=-=-=-=-=-=");
    }
  };
  const [viewstatus, setviewstatus] = useState(false);

  const submit_job_Details = async (e) => {
    try {
      e.preventDefault();
      if (
        departmentErrref.current == false &&
        jobtimingErrref.current == false &&
        jobPositionErrref.current == false &&
        locationErrref.current == false &&
        DescriptionErrref.current == false &&
        skillsErrref.current == false &&
        bannerErrref.current == false
      ) {
        var obj = {
          jobDepartment: inputvalues.jobDepartment,
          jobtiming: inputvalues.jobtiming,
          jobPosition: inputvalues.jobPosition,
          jobDescription: inputvalues.jobDescription,
          location: inputvalues.location,
          skills: inputvalues.skills,
          jobbanner: jobbanner,
          jobpoststatus: jobpoststatus,
          content: state.content,
          jobslug: Slug,
        };
        setLoader(true);
        var data = {
          apiUrl: apiService.submit_job_Details,
          payload: obj,
        };
        var resp = await postMethod(data);
        setLoader(false);
        if (resp.Status == true) {
          get_job_Details();
          toast.success(resp.Message);
          setformstatus(false);
          setState({
            content: "",
          });
        } else {
          toast.error(resp.Message);
        }
      } else {
        console.log("oooo");
      }
    } catch (error) {
      console.log(error, "-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-errerererer");
    }
  };
  const [jobDetails, setjobdetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalpages] = useState(0);
  const recordPerPage = 5;
  const pageRange = 5;

  const handlePageChange = (pageNumber) => {
    get_job_Details(pageNumber);
    setCurrentPage(pageNumber);
  };

  const get_job_Details = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page,
      };
      var data = {
        apiUrl: apiService.get_job_Details,
        payload: payload,
      };
      var resp = await postMethod(data);
      if (resp.Status == true) {
        setjobdetails(resp.data.data);
        setTotalpages(resp.data.total);
      } else {
      }
    } catch (error) {
      console.log(error, "-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-errerererer");
    }
  };

  const back = () => {
    setformstatus(false);
  };

  const [itemid, setitemid] = useState();

  const view_job_Details = async (item) => {
    try {
      setviewstatus(true);
      setformstatus(true);
      console.log(item);
      inputvalues.jobDepartment = item.jobDepartment;
      inputvalues.jobtiming = item.jobtiming;
      inputvalues.jobPosition = item.jobPosition;
      inputvalues.jobDescription = item.jobDescription;
      inputvalues.location = item.location;
      inputvalues.skills = item.skills;
      setJobBanner(item.jobbanner);
      setjobpoststatus(item.internpoststatus);
      setitemid(item._id);
      setSlug(item.jobslug);
      setState({
        content: item.content,
      });
    } catch (error) {
      console.log(error, "-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-errerererer");
    }
  };
  const edit_job_Details = async (e) => {
    try {
      e.preventDefault();
      var payload = {
        id: itemid,
        jobDepartment: inputvalues.jobDepartment,
        jobtiming: inputvalues.jobtiming,
        jobPosition: inputvalues.jobPosition,
        jobDescription: inputvalues.jobDescription,
        location: inputvalues.location,
        skills: inputvalues.skills,
        jobbanner: jobbanner,
        jobpoststatus: jobpoststatus,
        content: state.content,
        jobslug: Slug,
      };
      setLoader(true);
      var data = {
        apiUrl: apiService.edit_job_Details,
        payload: payload,
      };
      var resp = await postMethod(data);
      setLoader(false);
      if (resp.Status == true) {
        toast.success(resp.Message);
        setformstatus(false);
        get_job_Details();
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {
      console.log(error, "-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-errerererer");
    }
  };
  const delete_job_Details = async (item) => {
    try {
      var payload = {
        id: item._id,
      };
      var data = {
        apiUrl: apiService.delete_job_Details,
        payload: payload,
      };
      var resp = await postMethod(data);
      if (resp.Status == true) {
        toast.success(resp.Message);
        get_job_Details();
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {
      console.log(error, "-0-0-0-0-0-0-0-0-0-0-0-0-0-0-0-errerererer");
    }
  };
  const [formstatus, setformstatus] = useState(false);
  const formstatusChange = () => {
    try {
      setformstatus(true);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {formstatus == true ? (
        <>
          <PageTitle title="Job Form" />
          <div className="col-md-3 d-block float-right">
            <button className="btn btn-primary" onClick={back}>
              Back
            </button>
          </div>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <div className="card">
                <Grid container spacing={4} justify="center">
                  <Grid item xs={5}>
                    <form className="form_padding">
                      <div className="form-group">
                        <label>Opening Department</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Opening Department"
                          name="jobDepartment"
                          value={inputvalues.jobDepartment}
                          onChange={handleChange}
                        />
                        {departmentErr == true ? (
                          <p style={{ color: "red", fontSize: "14px" }}>
                            {validate.jobDepartment}
                          </p>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label>Opening Position</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Opening Position"
                          name="jobPosition"
                          value={inputvalues.jobPosition}
                          onChange={handleChange}
                        />
                        {jobPositionErr == true ? (
                          <p style={{ color: "red", fontSize: "14px" }}>
                            {validate.jobPosition}
                          </p>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label> Description</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=" Description"
                          name="jobDescription"
                          value={inputvalues.jobDescription}
                          onChange={handleChange}
                        />
                        {DescriptionErr == true ? (
                          <p style={{ color: "red", fontSize: "14px" }}>
                            {validate.jobDescription}
                          </p>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label>Location</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Location"
                          name="location"
                          value={inputvalues.location}
                          onChange={handleChange}
                        />
                        {locationErr == true ? (
                          <p style={{ color: "red", fontSize: "14px" }}>
                            {validate.location}
                          </p>
                        ) : null}
                      </div>

                      <div className="form-group">
                        <label>Timing</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Timing"
                          name="jobtiming"
                          value={inputvalues.jobtiming}
                          onChange={handleChange}
                        />
                        {jobtimingErr == true ? (
                          <p style={{ color: "red", fontSize: "14px" }}>
                            {validate.jobtiming}
                          </p>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label>Skills</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Skills"
                          name="skills"
                          value={inputvalues.skills}
                          onChange={handleChange}
                        />
                        {skillsErr == true ? (
                          <p style={{ color: "red", fontSize: "14px" }}>
                            {validate.skills}
                          </p>
                        ) : null}
                      </div>
                      <div className="form-group mb-4">
                        <label htmlFor="exampleInputPassword1">
                          Job Banner
                        </label>
                        <div>
                          <input
                            type="file"
                            className="custom-file"
                            id="customFile"
                            onChange={(e) => jobBannerUpload(e.target.files[0])}
                          />
                          {bannerErr == true ? (
                            <p style={{ color: "red", fontSize: "14px" }}>
                              {validate.jobbanner}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      {jobbanner == "" ||
                      jobbanner == null ||
                      jobbanner == undefined ? (
                        ""
                      ) : (
                        <img width="50" height="50" src={jobbanner} />
                      )}

                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">
                          Job Content
                        </label>

                        <CKEditor
                          activeClass="p10"
                          content={state.content}
                          events={{
                            blur: onBlur,
                            afterPaste: afterPaste,
                            change: onChange,
                          }}
                        />
                      </div>
                      <div class="form-group">
                        <label for="email"> Job Status</label>
                        <div className="copy_icon">
                          <select
                            id="input-gender"
                            className="form-control "
                            name="jobpoststatus"
                            value={jobpoststatus}
                            onChange={poststatus}
                          >
                            <option value="">Choose</option>
                            <option value="Active">Active</option>
                            <option value="DeActive">DeActive</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1"> Link</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=" URL"
                          value={Slugref.current}
                          disabled
                        />
                      </div>
                      {viewstatus == false ? (
                        loader == false ? (
                          <button
                            className="btn btn-primary custom_btn"
                            onClick={submit_job_Details}
                          >
                            Sumbit
                          </button>
                        ) : (
                          <button className="btn btn-primary custom_btn">
                            Loading...
                          </button>
                        )
                      ) : loader == false ? (
                        <button
                          className="btn btn-primary custom_btn"
                          onClick={edit_job_Details}
                        >
                          Sumbit
                        </button>
                      ) : (
                        <button className="btn btn-primary custom_btn">
                          Loading...
                        </button>
                      )}
                    </form>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <div className="row">
            <div className="col-md-9">
              <PageTitle title="Job Posts" className="my-3" />
            </div>
            <div className="col-md-3 d-block m-auto">
              <button className="btn btn-primary" onClick={formstatusChange}>
                Add
              </button>
            </div>
          </div>
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th> S.No</th>
                      <th> Department</th>
                      <th> Time </th>
                      <th> Position</th>
                      <th> Description</th>
                      <th> location</th>
                      <th> Banner</th>
                      <th> Status</th>
                      {/* <th> Action</th> */}
                      <th> Edit</th>
                      <th> Delete</th>
                    </tr>
                  </thead>

                  <tbody>
                    {jobDetails && jobDetails.length > 0 ? (
                      jobDetails.map((item, i) => {
                        return (
                          <tr key={item._id}>
                            <td>{i + 1}</td>
                            <td>{item.jobDepartment}</td>
                            <td>{item.jobtiming}</td>
                            <td>{item.jobPosition}</td>
                            <td>
                              <p>{item.jobDescription}</p>
                            </td>
                            <td>{item.location}</td>
                            <td>
                              <img width="50" src={item.jobbanner} />
                            </td>

                            <td>{item.jobpoststatus}</td>
                            <td
                              className="cursor-pointer"
                              onClick={() => view_job_Details(item)}
                            >
                              {" "}
                              <i className="fa fa-edit"></i>
                            </td>
                            <td
                              className="cursor-pointer"
                              onClick={() => delete_job_Details(item)}
                            >
                              {""}
                              <i className="fa fa-trash"></i>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="colSpan8">No records</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="row justify-content-end">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={currentPage}
                    itemsCountPerPage={recordPerPage}
                    totalItemsCount={totalPage}
                    pageRangeDisplayed={pageRange}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Job;
