export const getAuthToken = () => {
    if (localStorage.getItem('blfadmin_token')) {
        return localStorage.getItem('blfadmin_token')
    }
    return '';
}

export const removeAuthToken = () => {
    localStorage.removeItem('blfadmin_token');
    window.location.reload();

}

export const setAuthToken = (token) => {
    localStorage.setItem('blfadmin_token', token);
    return true
}

export const UsetAuthToken = (userName) =>{
    localStorage.setItem('user_token', userName);
    return true
}