import React from "react";
import {toast} from "react-toastify";
import apiService from "../core/service/detail";
import {postMethod} from "../core/service/common.api";

export { saveBlog, updateBlog, insertBlog };

// async function saveBlog( categoryname, categoryurl, history, setIsLoading, setError) {
//     setError(false);
//     setIsLoading(true);
//     if (!!categoryname && !!categoryurl) {
//         var data = {
//           apiUrl: apiService.saveBlogName,
//           payload: {categoryname:categoryname,categoryurl:categoryurl},
//         };
//         var resp = await postMethod(data);
//         if (resp.status) {
//             setError(null);
//             setIsLoading(false);
//             toast.success(resp.Message);
//         }else{
//           toast.error(resp.Message);
//         }
//       } else {
//         setError(true);
//         setIsLoading(false);
//       }

// }

async function saveBlog(categoryname,categorySlug, status,categoryblogSite,history) {
  // setError(false);
  // setIsLoading(true);
  console.log(categoryblogSite,"categoryblogSitecategoryblogSite")

  if (!!categoryname) {
      var data = {
        apiUrl: apiService.saveBlogName,
        payload: {categoryname:categoryname,categoryurl:categorySlug,status:status,categoryblogSite:categoryblogSite},
      };
      console.log(data,"datadatass")
      var resp = await postMethod(data);
      if (resp.status) {
          // setError(null);
          // setIsLoading(false);
          toast.success(resp.Message);
      }else{
        toast.error(resp.Message);
      }
    } else {
      // setError(true);
      // setIsLoading(false);
    }

}

async function updateBlog(id,name,status,history,categorySlug) {
  // setIsLoading(true);  
  var data = {
      apiUrl: apiService.updateBlogName,
      payload: {_id:id,categoryname:name,categoryurl:categorySlug,status:status,},
    };
    var resp = await postMethod(data);
    if (resp.status) {
        // setIsLoading(false);
        toast.success(resp.Message);
    }else{
      toast.error(resp.Message);
    }

}

async function insertBlog(title, slug, metatitle, metadesc, category, image, content, status,blogSite,catagaryUrl, history) {
  // setError(null);
  // setIsLoading(true);
  console.log("title===",title)
  console.log("slug===",slug)
  console.log("metatitle===",metatitle)
  console.log("metadesc===",metadesc)
  console.log("category===",category)
  console.log("image===",image)
  console.log("content===",content)
  console.log("status===",status)
  console.log("status===",catagaryUrl)
  console.log("status===",blogSite)

  if (title != "" &&  slug != "" && metatitle != "" && metadesc != "" && category != "" && image !="" && content != "" && status != "" && blogSite!="" ) {
      var data = {
        apiUrl: apiService.saveBlog,
        payload: {title:title,slug:slug,metatitle:metatitle,metadescription:metadesc,category:category,image:image,content:content,status:status,catagaryUrl:catagaryUrl,creator:"Admin",blogSite:blogSite},
      };
      console.log(data)
      var resp = await postMethod(data);
      if (resp.status) {
          // setError(null);
          // setIsLoading(false);
          toast.success(resp.Message);
      }else{
        toast.error(resp.Message);
      }
    } else {
      // setError(true);
      // setIsLoading(false);
      toast.error("Please fill all the fields");
    }

}