import React, { useEffect } from "react";

import useState from 'react-usestateref';


import Pagination from "react-js-pagination";
import apiService from "../core/service/detail";
import {getMethod} from "../core/service/common.api";
import {postMethod } from "../core/service/common.api";
import moment from "moment";
function Coursecontact() {
  const [currentPage, setCurrentPage] =useState(1);
  const [coursecontactlist,Setcoursecontactlist,Setcoursecontactlistref ] = useState([]);
  const [totalPage, setTotalpages] =useState(0);
  const recordPerPage = 5;
  const totalRecords = 15;
  const pageRange = 5;
  const handlePageChange = pageNumber => {
    getcoursecotactlist(pageNumber)
    setCurrentPage(pageNumber);
  }
  useEffect( () => {
     getcoursecotactlist();
  }, []);
  const getcoursecotactlist = async (page) => {
    try {
        var payload = {
          perpage: 5,
          page : page,
        }
        var data = {
        apiUrl: apiService.getcoursecotactlist,
        payload : payload
      };
      var resp = await postMethod(data);
      console.log(resp)
      if (resp.status) {
        console.log(resp.data.data,'resp.data.data')
        Setcoursecontactlist(resp.data.data);
        setTotalpages(resp.data.total);
      }
    } catch (error) {

    }
  }
  return (
    <div> Course Contact list
    <table class="table table-striped">
       <thead>
        <tr>
          <th> S.No</th>
          <th> Name </th>
          <th> Email</th>
          <th> Mobile Number</th>
          <th> Date</th>
        </tr>
       </thead>
      <tbody>
        {Setcoursecontactlistref.current.length> 0 ?(
          Setcoursecontactlistref.current&&Setcoursecontactlistref.current.map((list,i)=>{
            return(
              <tr>
                <td>
                  {i+1}
                </td>
                <td>
                  {list.name}
                </td>
                <td>
                  {list.email}
                </td>
                <td>
                  {list.mobileNumber}
                </td>
                <td>
                {moment(list.createdAt).format(("lll"))}
                </td>
              </tr>
            )
          })
        ):(
          <td className="justify-content-center">
          No CourseContact List Found
          </td>
        )
        }     
        </tbody>  
    </table>
    <Pagination
        itemClass="page-item" // add it for bootstrap 4
        linkClass="page-link" // add it for bootstrap 4
        ctivePage={currentPage}
        itemsCountPerPage={recordPerPage}
        totalItemsCount={totalPage}
        pageRangeDisplayed={pageRange}
        onChange={handlePageChange}
      />
    </div>

  )

  
}

export default Coursecontact;