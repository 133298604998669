import React,{ useEffect } from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import apiService from "../core/service/detail";
import {getMethod} from "../core/service/common.api";
import useState from 'react-usestateref';
import moment from "moment";

// components
import PageTitle from "../components/PageTitle/PageTitle";
// import Widget from "../../components/Widget";
// import Table from "../dashboard/components/Table/Table";

// data
// import mock from "../dashboard/mock";
const SPACED_DATE_FORMAT = "yyyy-MM-DD HH:mm:ss.SSS";

const columns = [
  {
   name: "userName",
   label: "Name",
   options: {
    filter: true,
    sort: true,
   }
  },
  {
   name: "email",
   label: "Email",
   options: {
    filter: true,
    sort: false,
   }
  },
  {
   name: "mobileNumber",
   label: "Mobile",
   options: {
    filter: true,
    sort: false,
   }
  },
  {
   name: "comments",
   label: "Message",
   options: {
    filter: true,
    sort: false,
   }
  },
   {
    name: "updatedAt",
    label: "Time",
    options: {
     filter: true,
     sort: false,
     customBodyRender: value =>
     moment(new Date(value)).format(SPACED_DATE_FORMAT)
    }
   },
 ];
export default function Tables() {
const [EnquiryList, setEnquiryList] = useState([])

  useEffect(async () => {
    getEnquiry();
    // getAllp2pOrders();
    // getAllcurrency();
  }, []);
  const getEnquiry = async () => {
    try {
        var data = {
        apiUrl: apiService.getEnquiryList,
      };
      var resp = await getMethod(data);
      if (resp.status) {
        setEnquiryList(resp.data);        
      }
    } catch (error) {

    }
  }
  return (
    <>
      <PageTitle title="Enquiry" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <div className="card">
            <Grid container spacing={4} justify="center">
              <Grid item xs={12}>
                <MUIDataTable
                  title="Category Name"
                  className="none-shodow"
                  data={EnquiryList}
                  columns={columns}
                  options={{
                    filterType: "checkbox",
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
