import React, { useEffect } from "react";
import useState from 'react-usestateref';
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import * as Icons from "@material-ui/icons";
import { CKEditor } from "ckeditor4-react";
import apiService from "../core/service/detail";
import { getMethod } from "../core/service/common.api";
import { postMethod } from "../core/service/common.api";
// components
import PageTitle from "../components/PageTitle/PageTitle";
import { useUserDispatch, insertBlog } from "../context/BlogContext";
import { toast } from "react-toastify";
import CKeditor from "../components/CKeditor";
import Pagination from "react-js-pagination";

const datatableData = [
  [
    "Joe James",
    "Example Inc.",
    <img src={require("../images/logo.png")} className="image-width" />,
    <Icons.Edit className="cursor-pointer" />,
    <Icons.Delete className="cursor-pointer" />,
  ],
];

export default function Tables(props) {
  var [categoryNameList, setcategoryNameList] = useState([])
  var [categoryNameValue, setcatagoryNameValue] = useState("");
  // var [isLoading, setIsLoading] = useState(false);
  // var [error, setError] = useState(null);
  var [blogList, setblogList, blogListref] = useState([])
  var [BlocksentinalblogList, setBlocksentinalblogList, BlocksentinalblogListref] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalpages] = useState(0);
  const [BlocksentinalcurrentPage, setBlocksentinalcurrentPage] = useState(1);
  const [BlocksentinaltotalPage, setBlocksentinaltotalPages] = useState(0);
  const [pageactive, setActivePage] = useState(false);
  var [blogTitle, setblogTitle] = useState("");
  // var [catagaryurl, setcatagaryUrl] = useState("");
  var [blogSlug, setblogSlug] = useState("");
  var [blogMetatitle, setblogMetatitle] = useState("");
  var [blogMetadesc, setblogMetadesc] = useState("");
  var [blogImage, setblogImage] = useState("");
  var [blogContent, setblogContent] = useState("");
  var [blogLabel, setblogLabel] = useState("Choose file");
  var [blogStatus, setblogStausValue] = useState("Active");
  var [blogSite, setblogSite] = useState("BLF");
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [blogdata, setBlog, blogdataref] = useState("");
  
  const recordPerPage = 5;
  // total number of the records
  const totalRecords = 15;
  // range of pages in paginator
  const pageRange = 5;
  
  const BlocksentinalrecordPerPage = 5;
  // total number of the records
  const BlocksentinaltotalRecords = 15;
  // range of pages in paginator
  const BlocksentinalpageRange = 5;


  const handlePageBlocksentinalChange = pageNumber => {
    getBlocksentinalblogList(pageNumber)
    setBlocksentinalcurrentPage(pageNumber);
    // setchoosepage()
  }
  const handlePageChange = pageNumber => {
    getblogList(pageNumber)
    setCurrentPage(pageNumber);
    // setchoosepage()
  }

  useEffect(() => {
    async function fetchData() {
      getcategoryNameList();
      getblogList();
      getBlocksentinalblogList();
    }
    fetchData();
    setEditorLoaded(true);
  }, []);

  const getcategoryNameList = async () => {
    try {
      var data = {
        apiUrl: apiService.get_blog_category_list,
      };
      var resp = await getMethod(data);
      console.log("category list====", resp);
      if (resp.status) {
        setcategoryNameList(resp.data);
      }
    } catch (error) {

    }
  }

  const getBlocksentinalblogList = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page
      }
      var data = {
        apiUrl: apiService.get_Blocksentinal_blog_List,
        payload: payload
      };
      var resp = await postMethod(data);
      if (resp.status) {
        setBlocksentinalblogList(resp.data.data);
        console.log(resp.data.data,)
        setBlocksentinaltotalPages(resp.data.total);
      }
    } catch (error) {

    }
  }
  const getblogList = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page
      }
      var data = {
        apiUrl: apiService.get_blog_list,
        payload: payload
      };
      var resp = await postMethod(data);
      if (resp.status) {
        setblogList(resp.data.data);
        console.log(resp.data.data,)
        setTotalpages(resp.data.total);
      }
    } catch (error) {

    }
  }

  const imageUpload = (val) => {
    console.log("val===", val)
    const fileExtension = val.name.split(".").at(-1);
    const fileSize = val.size;
    const fileName = val.name;
    console.log("fileExtension===", fileExtension);
    console.log("fileSize===", fileSize);
    console.log("fileName===", fileName);
    if (fileExtension != "png" && fileExtension != "jpg" && fileExtension != "jpeg" && fileExtension != "webp") {
      toast.error("File does not support. You must use .png or .jpg or .jpeg ");
      return false;
    }
    else if (fileSize > 1000000) {
      toast.error("Please upload a file smaller than 1 MB");
      return false;
    }
    else {
      const data = new FormData()
      data.append("file", val)
      data.append("upload_preset", "ebjirh4i")
      data.append("cloud_name", "blf")
      console.log("formdata===", data)
      fetch("  https://api.cloudinary.com/v1_1/blf/auto/upload", {
        method: "post",
        body: data
      })
        .then(resp => resp.json())
        .then(data => {
          console.log("cloudinary upload===", data);
          setblogImage(data.secure_url);
          setblogLabel(fileName);
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  const setblogUrl = (val) => {
    var title = val;
    setblogTitle(title);
    var slug = title.toLowerCase().replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
    setblogSlug(slug);
  }

  const setblogContents = (data) => {
    console.log("blog data====", data);
    setblogContent(data);
  };

  const editBlog = async (title, slug, metatitle, metadesc, category, image, status,content, history, setIsLoading) => {
    console.log(category,)
    var data = {
      apiUrl: apiService.editBlog,
      payload: { _id : blogdataref.current,title: title, slug: slug, metatitle: metatitle, metadescription: metadesc, category: category, image: image, status: status ,content: content},
    };
    console.log(data,"payload=-=-=-data-=-=-=-=-=-=---0-0-0-")
    var resp = await postMethod(data);
    console.log(resp,"resp=-=-=-resp-=-=-=-=-=-=---0-0-0-")
   
    if(resp){
      toast(resp.Message)
      getblogList();
      // setActivePage(false)
    }
  }

  const viewBlog = async (e) => {
    setActivePage(true);
    console.log(e, "=========value")
    setBlog(e._id);
    setblogTitle(e.title);
    setblogSlug(e.slug);
    setblogMetatitle(e.metatitle);
    setblogMetadesc(e.metadescription);
    setcatagoryNameValue(e.category_id);
    setblogImage(e.image);
    setblogContent(e.content);
    setblogStausValue(e.status);
    setblogSite(e.blogSite);
    
    console.log(e.title)
    console.log(e.slug)
    console.log(e.metatitle)
    console.log(e.metadescription)
    console.log(e.category)
    console.log(e.image)
    console.log(e.content)
    console.log(e.status)
    console.log(e.blogSite)
  }

  const deleteBlog =async (e) =>{
console.log(e)
var obj={e}
var data = {
  apiUrl: apiService.deleteblog,
  payload:obj
};
var resp = await postMethod(data);
if(resp){
  toast(resp.Message)
}
console.log(resp,"resp=-=-=-=res-=-=-=-=-=-=rsp")
  }
  return (
    <>
      <PageTitle title="Blog Upload" />
      {pageactive == false ? (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <div className="card">
              <Grid container spacing={4} justify="center">
                <Grid item xs={5}>
                  <form className="form_padding">
                    <div className="form-group">
                      <label>Blog Title</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Blog Title"
                        value={blogTitle}
                        onChange={(e) => setblogUrl(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Blog URL</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Blog URL"
                        value={blogSlug}
                        disabled
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Blog META title</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Blog META title"
                        value={blogMetatitle}
                        onChange={(e) => setblogMetatitle(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">
                        Blog META Description
                      </label>
                      <textarea rows="5" className="form-control" onChange={(e) => setblogMetadesc(e.target.value)}>{blogMetadesc}</textarea>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Blog category</label>
                      <select className="form-control" id="exampleFormControlSelect1" onChange={(e) => setcatagoryNameValue(e.target.value)}>
                        {categoryNameList && categoryNameList.map((option) => (
                          <option value={option._id}>{option.categoryname}</option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group mb-4">
                      <label htmlFor="exampleInputPassword1">Blog Image</label>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFile"
                          onChange={(e) => imageUpload(e.target.files[0])}
                        />
                        <label className="custom-file-label" htmlFor="customFile">
                          {blogLabel}
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Blog Content</label>
                      <CKeditor
                        name="description"
                        onChange={(data) => {
                          setblogContents(data);
                        }}
                        editorLoaded={editorLoaded}
                      />
                    </div>
                    <div className="form-group">
                      <label>Site name</label>
                      <select className="form-control" id="exampleFormControlSelect1" onChange={(e) => setblogSite(e.target.value)}>
                        <option value='BLF'>BLF</option>
                        <option value='Blocksentels'>Blocksentels</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Status</label>
                      <select className="form-control" id="exampleFormControlSelect1" onChange={(e) => setblogStausValue(e.target.value)}>
                        <option value='Active'>Active</option>
                        <option value='Deactive'>Deactive</option>
                      </select>
                    </div>
                    <button disabled={blogTitle.length === 0 || blogSlug.length === 0 || blogMetatitle.length === 0 || blogMetadesc.length === 0 || categoryNameValue.length === 0 || blogImage.length === 0 || blogContent.length === 0 || blogStatus.length === 0||blogSite.length===0} className="btn btn-primary custom_btn" onClick={e => (e.preventDefault(), insertBlog(blogTitle, blogSlug, blogMetatitle, blogMetadesc, categoryNameValue, blogImage, blogContent, blogStatus,blogSite,
                      props.history,
                      // setIsLoading,
                      // setError
                      ))}>
                      Submit
                    </button>
                  </form>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <div className="card">
              <Grid container spacing={4} justify="center">
                <Grid item xs={5}>
                  <form className="form_padding">
                    <div className="form-group">
                      <label>Blog Title</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Blog Title"
                        value={blogTitle}
                        onChange={(e) => setblogUrl(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Blog URL</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Blog URL"
                        value={blogSlug}
                        disabled
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Blog META title</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Blog META title"
                        value={blogMetatitle}
                        onChange={(e) => setblogMetatitle(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">
                        Blog META Description
                      </label>
                      <textarea rows="5" value={blogMetadesc} className="form-control" onChange={(e) => setblogMetadesc(e.target.value)}>{blogMetadesc}</textarea>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Blog category</label>
                      <select className="form-control" id="exampleFormControlSelect1" onChange={(e) => setcatagoryNameValue(e.target.value)}>
                        {categoryNameList && categoryNameList.map((option) => (
                          <option value={option._id}>{option.categoryname}</option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group mb-4">
                      <label htmlFor="exampleInputPassword1">Blog Image</label>
                      <img width="200" src={blogImage} />
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFile"
                          // value={blogImage}
                          onChange={(e) => imageUpload(e.target.files[0])}
                        />
                        <label className="custom-file-label" htmlFor="customFile">
                          {blogLabel}
                        </label>
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Blog Content</label>
                      <CKeditor
                        name="description"
                        value={blogContent}
                        onChange={(data) => {
                          setblogContents(data);
                        }}
                        editorLoaded={editorLoaded}
                        data={blogContent}
                      />
                    </div>
                    <div className="form-group">
                      <label>Site name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Blog META title"
                        value={blogSite}
                        // onChange={(e) => setblogMetatitle(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label>Status</label>
                      <select className="form-control" value={blogStatus} id="exampleFormControlSelect1" onChange={(e) => setblogStausValue(e.target.value)}>
                        <option value='Active'>Active</option>
                        <option value='Deactive'>Deactive</option>
                      </select>
                    </div>
                    <button disabled={blogTitle.length === 0 || blogSlug.length === 0 || blogMetatitle.length === 0 || blogMetadesc.length === 0 || categoryNameValue.length === 0 || blogImage.length === 0 || blogContent.length === 0 || blogStatus.length === 0} className="btn btn-primary custom_btn" onClick={e => (e.preventDefault(), editBlog(blogTitle, blogSlug, blogMetatitle, blogMetadesc, categoryNameValue, blogImage,blogStatus, blogContent,
                      props.history,
                      // setIsLoading,
                      // setError
                      ))}>
                      UpdateBlog
                    </button>
                  </form>
                </Grid>
                  </Grid>
            </div>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid >
      )
      }
      <div>
        Beleaf Blogs
        <table class="table table-striped">
          <thead>
            <tr>
              <th> S.No</th>
              <th> Blog Title </th>
              <th> Category Name </th>
              <th> Image </th>
              <th> Status </th>
              <th> Action </th>
            </tr>
          </thead>
          <tbody>
            {
              blogListref.current && blogListref.current.map((blogs, i) => {
                return (
                  <tr>
                    <td>
                      {i + 1}
                    </td>
                    <td>
                      {blogs.title}
                    </td>
                    <td>
                      {blogs.category}
                    </td>
                    <td>
                      <img width="50" src={blogs.image} />
                    </td>
                    <td>
                      {blogs.status == "Active" ? <p style={{ color: "green" }} > Active </p> : <p style={{ color: "red" }}> Deactive </p>}
                    </td>
                    <td onClick={() => viewBlog(blogs)}>
                      <i class="fa fa-edit"></i>
                      <a href="javascript:void(0)">  Edit </a>

                    </td>
                    <td onClick={() => deleteBlog(blogs._id)}>
                  {/* <i class="fa-solid fa-trash-xmark"></i> */}
                      <a href="javascript:void(0)">  Delete </a>

                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <Pagination
          itemClass="page-item" // add it for bootstrap 4
          linkClass="page-link" // add it for bootstrap 4
          activePage={currentPage}
          itemsCountPerPage={recordPerPage}
          totalItemsCount={totalPage}
          pageRangeDisplayed={pageRange}
          onChange={handlePageChange}
        />
      </div>

      <div>
        Block Sentinals Blogs
        <table class="table table-striped">
          <thead>
            <tr>
              <th> S.No</th>
              <th> Blog Title </th>
              <th> Category Name </th>
              <th> Image </th>
              <th> Status </th>
              <th> Action </th>
            </tr>
          </thead>
          <tbody>
            {BlocksentinalblogListref.current.length >0 ?
              BlocksentinalblogListref.current && BlocksentinalblogListref.current.map((blogs, i) => {
                return (
                  <tr>
                    <td>
                      {i + 1}
                    </td>
                    <td>
                      {blogs.title}
                    </td>
                    <td>
                      {blogs.category}
                    </td>
                    <td>
                      <img width="50" src={blogs.image} />
                    </td>
                    <td>
                      {blogs.status == "Active" ? <p style={{ color: "green" }} > Active </p> : <p style={{ color: "red" }}> Deactive </p>}
                    </td>
                    <td onClick={() => viewBlog(blogs)}>
                      <i class="fa fa-edit"></i>
                      <a href="javascript:void(0)">  Edit </a>

                    </td>
                    <td onClick={() => deleteBlog(blogs._id)}>
                  {/* <i class="fa-solid fa-trash-xmark"></i> */}
                      <a href="javascript:void(0)">  Delete </a>

                    </td>
                  </tr>
                )
              })
              :
              "No Record Found"
            }
          </tbody>
        </table>
        {BlocksentinalblogListref.current.length >0 ?

        <Pagination
          itemClass="page-item" // add it for bootstrap 4
          linkClass="page-link" // add it for bootstrap 4
          activePage={BlocksentinalcurrentPage}
          itemsCountPerPage={BlocksentinalrecordPerPage}
          totalItemsCount={BlocksentinaltotalPage}
          pageRangeDisplayed={BlocksentinalpageRange}
          onChange={handlePageBlocksentinalChange}
        />
        :""}
      </div>
    </>
  );
}
