import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import * as Icons from "@material-ui/icons";
import { useUserDispatch, saveBlog, updateBlog } from "../context/BlogContext";
import apiService from "../core/service/detail";
import { getMethod } from "../core/service/common.api";
import { postMethod } from "../core/service/common.api";
// import { PdfImage } from '../images/PDF_file_icon.svg.png'
import PdfImage from "../images/PDF_file_icon.svg.png";
import Pagination from "react-js-pagination";
import moment from "moment";

// components
import PageTitle from "../components/PageTitle";
// import Widget from "../../components/Widget";
// import Table from "../dashboard/components/Table/Table";

// data
// import mock from "../dashboard/mock";

const SPACED_DATE_FORMAT = "yyyy-MM-DD HH:mm:ss.SSS";

const datatableData = [
  [
    "Joe James",
    "Example Inc.",
    <Icons.Edit className="cursor-pointer" />,
    <Icons.Delete className="cursor-pointer" />,
  ],
  [
    "Joe James",
    "Example Inc.",
    <Icons.Edit className="cursor-pointer" />,
    <Icons.Delete className="cursor-pointer" />,
  ],
  [
    "Joe James",
    "Example Inc.",
    <Icons.Edit className="cursor-pointer" />,
    <Icons.Delete className="cursor-pointer" />,
  ],
  [
    "Joe James",
    "Example Inc.",
    <Icons.Edit className="cursor-pointer" />,
    <Icons.Delete className="cursor-pointer" />,
  ],
  [
    "Joe James",
    "Example Inc.",
    <Icons.Edit className="cursor-pointer" />,
    <Icons.Delete className="cursor-pointer" />,
  ],
];

const columns = [
  {
    name: "categoryname",
    label: "Category Name",
    options: {
      filter: true,
      sort: true,
    },
  },
  // {
  //  name: "categoryurl",
  //  label: "Category URL",
  //  options: {
  //   filter: true,
  //   sort: false,
  //  }
  // },
  {
    name: "updatedAt",
    label: "Time",
    options: {
      filter: true,
      sort: false,
      customBodyRender: (value) =>
        moment(new Date(value)).format(SPACED_DATE_FORMAT),
    },
  },
];

export default function Tables(props) {
  var [categoryNameValue, setcatagoryNameValue] = useState("");
  var [categorySlug, setcategorySlug] = useState("");

  var [categoryStatusValue, setcategoryStausValue] = useState(1);
  var [categoryblogSite, setcategoryblogSite, setcategoryblogSiteref] =
    useState("BLF");
  console.log(categoryblogSite);
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [categoryNameList, setcategoryNameList, categoryNameListref] = useState(
    [],
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalpages] = useState(0);
  const [pageactive, setActivePage, pageactiveref] = useState("add");
  const [categorydata, setCategory, categorydataref] = useState("");
  const [BlocksentelscurrentPage, setBlocksentelscurrentPage] = useState(1);
  const [BlocksentelstotalPage, setBlocksentelstotalPage] = useState(0);
  var [
    BlocksentelscategoryNameList,
    setBlocksentelscategoryNameList,
    BlocksentelscategoryNameListref,
  ] = useState([]);

  const recordPerPage = 5;
  // total number of the records
  const totalRecords = 15;
  // range of pages in paginator
  const pageRange = 5;

  const BlocksentelsrecordPerPage = 5;
  // total number of the records
  // const totalRecords = 15;
  // range of pages in paginator
  const BlocksentelspageRange = 5;

  const handlePageBlocksentelsChange = (pageNumber) => {
    getBlockSentinalList(pageNumber);
    setBlocksentelscurrentPage(pageNumber);

    // setchoosepage()
  };

  const handlePageChange = (pageNumber) => {
    getcategoryNameList(pageNumber);
    setCurrentPage(pageNumber);
    // setchoosepage()
  };

  useEffect(() => {
    getcategoryNameList();
    getBlockSentinalList();
  }, []);

  const getcategoryNameList = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page,
      };
      var data = {
        apiUrl: apiService.get_catagary_list,
        payload: payload,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        console.log(resp, "========resp");
        setcategoryNameList(resp.data.data);
        setTotalpages(resp.data.total);
      }
    } catch (error) {}
  };

  const getBlockSentinalList = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page,
      };
      var data = {
        apiUrl: apiService.getBlockSentinalList,
        payload: payload,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        console.log(resp, "========resp");
        setBlocksentelscategoryNameList(resp.data.data);
        setBlocksentelstotalPage(resp.data.total);
      }
    } catch (error) {}
  };

  const viewBlog = async (e) => {
    setActivePage("edit");
    setCategory(e);
    setcatagoryNameValue(categorydataref.current.categoryname);
    setcategoryStausValue(categorydataref.current.status);
    setcategorySlug(categorydataref.current.slug);
    console.log("categorydataref===", categorydataref.current);
  };

  // const enquiry = async  (e) => {

  //   try {

  //     var payload = {
  //       _id : e._id,
  //       status : "read"
  //     }
  //     var data = {
  //       apiUrl: apiService.changecareerstatus,
  //       payload : payload
  //     };

  //     var resp = await postMethod(data);
  //     if(resp.status){
  //       getContacts();
  //     }else{

  //     }

  //   } catch (error) {
  //     console.log('=-=-=-=-=-=-=-=-',error,'=-=-=-=-=-error-=-=')
  //   }

  // }
  const setcatagaryUrl = (val) => {
    var catagaryname = val;
    setcatagoryNameValue(catagaryname);
    var slug = catagaryname
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]+/g, "");
    setcategorySlug(slug);
  };

  return (
    <>
      <PageTitle title="Blog Category" />
      {pageactiveref.current == "add" ? (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <div className="card">
              <Grid container spacing={4} justify="center">
                <Grid item xs={5}>
                  <form className="form_padding">
                    <div className="form-group">
                      <label>SiteName</label>
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        onChange={(e) => setcategoryblogSite(e.target.value)}
                      >
                        <option value="BLF">BLF</option>
                        <option value="Blocksentels">Blocksentels</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label>Category Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Category Name"
                        value={categoryNameValue}
                        onChange={(e) => setcatagaryUrl(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">
                        Catagary Url
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Category URL"
                        value={categorySlug}
                        disabled
                      />
                    </div>
                    <div className="form-group">
                      <label>Status</label>
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        onChange={(e) => setcategoryStausValue(e.target.value)}
                      >
                        <option value="1">Active</option>
                        <option value="0">Deactive</option>
                      </select>
                    </div>

                    <button
                      disabled={
                        categoryNameValue.length === 0 ||
                        categorySlug === 0 ||
                        categoryStatusValue.length === 0
                      }
                      className="btn btn-primary custom_btn"
                      onClick={(e) => (
                        e.preventDefault(),
                        saveBlog(
                          categoryNameValue,
                          categorySlug,
                          categoryStatusValue,
                          setcategoryblogSiteref.current,
                          props.history,
                          setIsLoading,
                          setError,
                        )
                      )}
                    >
                      Submit
                    </button>
                  </form>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      ) : (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <div className="card">
              <Grid container spacing={4} justify="center">
                <Grid item xs={5}>
                  <form className="form_padding">
                    <div className="form-group">
                      <label>Category Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Category Name"
                        //value={categorydataref.current.categoryname}
                        value={categoryNameValue}
                        onChange={(e) => setcatagaryUrl(e.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">
                        Catagary Url
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Category URL"
                        value={categorySlug}
                        disabled
                      />
                    </div>
                    <div className="form-group">
                      <label>Status</label>
                      <select
                        className="form-control"
                        id="exampleFormControlSelect1"
                        onChange={(e) => setcategoryStausValue(e.target.value)}
                      >
                        <option value="1">Active</option>
                        <option value="0">Deactive</option>
                      </select>
                    </div>
                    <button
                      className="btn btn-primary custom_btn"
                      onClick={(e) => (
                        e.preventDefault(),
                        updateBlog(
                          categorydataref.current._id,
                          categoryNameValue,
                          categorySlug,
                          categoryStatusValue,
                          props.history,
                          setIsLoading,
                          setError,
                        )
                      )}
                    >
                      Submit
                    </button>
                  </form>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      )}
      <div>
        Beleaf Blog Categories
        <table class="table table-striped">
          <thead>
            <tr>
              <th> S.No</th>
              <th> Category Name </th>
              <th> createdAt </th>
              <th> Status </th>
              <th> Action </th>
            </tr>
          </thead>

          <tbody>
            {categoryNameListref.current &&
              categoryNameListref.current.map((categories, i) => {
                console.log(categories);
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{categories.categoryname}</td>
                    <td>{moment(categories.createdAt).format("lll")}</td>
                    <td>
                      {categories.status == 1 ? (
                        <p style={{ color: "green" }}> Active </p>
                      ) : (
                        <p style={{ color: "red" }}> Deactive </p>
                      )}
                    </td>
                    <td onClick={() => viewBlog(categories)}>
                      <i class="fa fa-edit"></i>
                      <a href="javascript:void(0)"> Edit </a>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <Pagination
          itemClass="page-item" // add it for bootstrap 4
          linkClass="page-link" // add it for bootstrap 4
          activePage={currentPage}
          itemsCountPerPage={recordPerPage}
          totalItemsCount={totalPage}
          pageRangeDisplayed={pageRange}
          onChange={handlePageChange}
        />
      </div>
      <div>
        Block Sentinals Blog Categories
        <table class="table table-striped">
          <thead>
            <tr>
              <th> S.No</th>
              <th> Category Name </th>
              <th> createdAt </th>
              <th> Status </th>
              <th> Action </th>
            </tr>
          </thead>

          <tbody>
            {BlocksentelscategoryNameListref.current &&
              BlocksentelscategoryNameListref.current.map((categories, i) => {
                console.log(categories);
                return (
                  <tr>
                    <td>{i + 1}</td>
                    <td>{categories.categoryname}</td>
                    <td>{moment(categories.createdAt).format("lll")}</td>
                    <td>
                      {categories.status == 1 ? (
                        <p style={{ color: "green" }}> Active </p>
                      ) : (
                        <p style={{ color: "red" }}> Deactive </p>
                      )}
                    </td>
                    <td onClick={() => viewBlog(categories)}>
                      <i class="fa fa-edit"></i>
                      <a href="javascript:void(0)"> Edit </a>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
        <Pagination
          itemClass="page-item" // add it for bootstrap 4
          linkClass="page-link" // add it for bootstrap 4
          activePage={BlocksentelscurrentPage}
          itemsCountPerPage={BlocksentelsrecordPerPage}
          totalItemsCount={BlocksentelstotalPage}
          pageRangeDisplayed={BlocksentelspageRange}
          onChange={handlePageBlocksentelsChange}
        />
      </div>
    </>
  );
}
