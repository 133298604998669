import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import apiService from "../core/service/detail";
import { getMethod } from "../core/service/common.api";
import { postMethod } from "../core/service/common.api";
// components
import PageTitle from "../components/PageTitle/PageTitle";
import { uploadBook } from "../context/BookContext";
import { toast } from "react-toastify";
import axios from 'axios';
var FormData = require('form-data')

export default function Book(props) {
  var [bookTitle, setBookTitle] = useState("");
  var [bookDescription, setBookDescription] = useState("");
  var [coverImage, setCoverImage] = useState("");
  var [coverImageLabel, setCoverImageLabel] = useState("Choose File");
  var [image, setImage] = useState();
  var [bookPdf, setBookPdf] = useState("");
  var [bookPdfLabel, setBookPdfLabel] = useState("")
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [bookDetails, setBookDetails] = useState([]);
  useEffect(() => {
    getBookList();
  }, [0]);

  const getBookList = async () => {
    var data = {
      apiUrl: apiService.getBookList,
    };
    var resp = await getMethod(data);
    console.log(resp.Message.book_Details, "respresp");
    setBookDetails(resp.Message.book_Details);
  };

  const coverImageUpload = (val) => {
    console.log("val===", val);
    const fileExtension = val.name.split(".").at(-1);
    const fileSize = val.size;
    const fileName = val.name;
    console.log("fileExtension===", fileExtension);
    console.log("fileSize===", fileSize);
    console.log("fileName===", fileName);
    if (
      fileExtension != "png" &&
      fileExtension != "jpg" &&
      fileExtension != "jpeg"&&
      fileExtension != "pdf"
    ) {
      toast.error("File does not support. You must use .png or .jpg or .jpeg ");
      return false;
    } else if (fileSize > 1000000) {
      toast.error("Please upload a file smaller than 1 MB");
      return false;
    } else {
      const data = new FormData();
      data.append("file", val);
      data.append("upload_preset", "utewponb");
      data.append("cloud_name", "dpnvbcrn2");
      console.log("formdata===", data);
      fetch("  https://api.cloudinary.com/v1_1/dpnvbcrn2/auto/upload", {
        method: "post",
        body: data,
      })
        .then((resp) => resp.json())
        .then((data) => {
          console.log("cloudinary upload===", data);
          console.log("cloudinary upload===", data.secure_url);
          setCoverImage(data.secure_url);
          setCoverImageLabel(fileName);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const BookPdfUpload = (val, e) => {
    try{
      console.log("val===", val);
      setImage(val)
    const fileExtension = val.name.split(".").at(-1);
    const fileSize = val.size;
    const fileName = val.name;
    console.log("fileExtension===", fileExtension);
    console.log("fileSize===", fileSize);
    console.log("fileName===", fileName);
    if (
      fileExtension != "pdf" 
    ) {
      toast.error("File does not support. You must use .pdf");
      return false;
    }  else {
      const formData=new FormData();
      formData.append('PDF',val)
      //axios.post("http://localhost:3001/upload",formData).then(res=>{
        axios.post("https://beleaftechnologies.com:3001/upload",formData).then(res=>{
        var split=res.data.Message.path.split("/")
        var pdfUrl=split[1]+"/"+split[2]
        console.log(pdfUrl,"spispi")
        console.log(res.data.Message.path,"res")
        setBookPdfLabel(pdfUrl)
       
        setBookPdf(pdfUrl)
      }).catch(error=>{
        console.log(error,"error")
      })
     
    } 
  
  }catch(error){
console.log("error",error)
    }
   


  };

  return (
    <>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <div className="card">
            <Grid container spacing={4} justify="center">
              <Grid item xs={5}>
                <form className="form_padding">
                  <div className="form-group">
                    <label>Book Title</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Book Title"
                      value={bookTitle}
                      onChange={(e) => setBookTitle(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputPassword1">
                      Book Description
                    </label>
                    <textarea
                      rows="5"
                      className="form-control"
                      value={bookDescription}
                      onChange={(e) => setBookDescription(e.target.value)}
                    ></textarea>
                  </div>

                  <div className="form-group mb-4">
                    <label htmlFor="exampleInputPassword1">
                      Book Cover Image
                    </label>
                    <img width="200" src="" />
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="customFile"
                        onChange={(e) => coverImageUpload(e.target.files[0])}
                      />
                      <label className="custom-file-label" htmlFor="customFile">
                        {coverImageLabel}
                      </label>
                    </div>
                  </div>

                  <div className="form-group mb-4">
                    <label htmlFor="exampleInputPassword1">Book PDF</label>
                    <img width="200"/>
                    <div className="custom-file">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="file_id"
                        onChange={(e)=>BookPdfUpload(e.target.files[0])} 
                      />
                      <label className="custom-file-label" htmlFor="customFile">
                        {bookPdfLabel}
                      </label>
                    </div>
                  </div>

                  <button
                    className="btn btn-primary custom_btn"
                    disabled={
                      bookTitle.length == 0 ||
                      bookDescription.length == 0 ||
                      coverImage == 0 ||
                      bookPdf == 0
                    }
                    onClick={(e) => (
                      e.preventDefault(),
                      uploadBook(
                        bookTitle,
                        bookDescription,
                        coverImage,
                        bookPdf,
                        props.history,
                        setIsLoading,
                        setError,
                      )
                    )}
                  >
                    Submit
                  </button>
                </form>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
      <PageTitle title="Created Book List" />
      <Grid item xs={12}>
        <MUIDataTable
          data={bookDetails}
          columns={["BookId", "BookTittle", "CoverImage", "UploadDate"]}
        />
      </Grid>
    </>
  );
}
