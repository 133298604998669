import React, { useState, useEffect } from 'react'
import PageTitle from "../components/PageTitle/PageTitle";
import { Button, Grid } from "@material-ui/core";
import apiService from "../core/service/detail";
import { getMethod, postMethod } from "../core/service/common.api";
import { toast } from "react-toastify";

function Banersection() {

    const values = {
        Banercontant: "",
        Banersubcontant: "",
        Whybeleaf: ""
    }

    const [BanerSection, setBanerSection] = useState(false)
    var [blogLabel, setblogLabel] = useState("Choose file");
    const [blogImage, setblogImage] = useState("")
    const [formValue, setFormValue] = useState(values)
    const [details, setdetails] = useState([])
    const [editDetails, seteditDetails] = useState(false)
    const [idvalue, setidvalue] = useState("")
    const { Banercontant, Banersubcontant, Whybeleaf } = formValue;
    const [BanercontantE, setBanercontantE] = useState(false)
    const [BanersubcontantE, setBanersubcontantE] = useState(false)
    const [WhybeleafE, setWhybeleafE] = useState(false)
    const [validationnErr, setvalidationnErr] = useState(false)



    useEffect(() => {
        getData()
    }, []);

    const validation = () => {
        const error = {}
        if (Banercontant == "") {
            error.Banercontant = "Banercontant is a required field";
            setBanercontantE(true);
            console.log("view")
        }
        if (Banersubcontant == "") {
            error.Shortsubcontent = "Banersubcontant is a required field";
            setBanersubcontantE(true);
        }
        if (Whybeleaf == "") {
            error.Whybeleaf = "Whybeleaf is a required field";
            setWhybeleafE(true);
        }
        setvalidationnErr(error);
        return error;

    }

    const formSubmit = async (e) => {
        if (e && e.preventDefault) { e.preventDefault(); }
        validation(formValue);
        if (formValue.Banercontant != "" &&
            formValue.Banersubcontant != "" &&
            formValue.Whybeleaf != "") {

            if (blogImage == "") {
                console.log("error")
            } else {
                formValue["Image"] = blogImage
            }
            var data = {
                apiUrl: apiService.BanerSection,
                payload: formValue,
                // image: blogImage
            };
            console.log(data, "=-==-data=-=-=-");
            var resp = await postMethod(data);
            toast(resp.Message)
            getData()
            seteditDetails(true)

        } else {
            console.log("error")
        }

    }
    const getData = async () => {
        var value = {
            apiUrl: apiService.getBanerSection,
        };
        console.log(value, "=-==-data=-=-=-");
        var resp = await getMethod(value);

        console.log(resp.data, "=-=-=-responce=-=-=")
        setdetails(resp.data)
        seteditDetails(true)

    }
    const imageUpload = (val) => {
        console.log("val===", val)
        const fileExtension = val.name.split(".").at(-1);
        const fileSize = val.size;
        const fileName = val.name;
        console.log("fileExtension===", fileExtension);
        console.log("fileSize===", fileSize);
        console.log("fileName===", fileName);
        if (fileExtension != "png" && fileExtension != "jpg" && fileExtension != "jpeg") {
            // toast.error("File does not support. You must use .png or .jpg or .jpeg ");
            return false;
        }
        else if (fileSize > 1000000) {
            //   toast.error("Please upload a file smaller than 1 MB");
            return false;
        }
        else {
            const data = new FormData()
            data.append("file", val)
            data.append("upload_preset", "ebjirh4i")
            data.append("cloud_name", "blf")
            console.log("formdata===", data)
            fetch("  https://api.cloudinary.com/v1_1/blf/auto/upload", {
                method: "post",
                body: data
            })
                .then(resp => resp.json())
                .then(data => {
                    console.log("cloudinary upload===", data);
                    setblogImage(data.secure_url);
                    setblogLabel(fileName);
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    const getvalue = (e) => {
        // e.preventDefault()
        if (e && e.preventDefault) { e.preventDefault(); }
        const { name, value } = e.target;
        let formData = { ...formValue, ...{ [name]: value } };
        setFormValue(formData);
        console.log(formValue, "=-=-=-===-=-")

    }

    const editFrom = async (data) => {
        seteditDetails(false)
        setBanerSection(false)
        var edit = {
            apiUrl: apiService.geteditBaner,
            payload: data
        };
        console.log(edit, "=-==-data=-=-=-");
        var response = await postMethod(edit);
        console.log(response, "=-=-=-response=-=-=-")
        if (response.Status == true) {
            setidvalue(response.data._id)
            setblogImage(response.data.Profile)
            formValue.Banercontant = response.data.Banercontant
            formValue.Banersubcontant = response.data.Banersubcontant
            formValue.Whybeleaf = response.data.Whybeleaf
            console.log(response.data.Banercontant, "response.data.Banercontant")
            console.log(response.data.Banersubcontant, "response.data.Banersubcontant")
            console.log(response.data.Whybeleaf, "response.data.Whybeleaf")
        }
        console.log(formValue.Whybeleaf, "==--=-=-=-=-=-=-=-")
    }


    const editBaner = async (e) => {
        if (e && e.preventDefault) { e.preventDefault(); }
        var obj = {
            _id: idvalue,
            Banercontant: formValue.Banercontant,
            Banersubcontant: formValue.Banersubcontant,
            Whybeleaf: formValue.Whybeleaf,
            Image: blogImage
        };
        var edit = {
            apiUrl: apiService.editBanerSection,
            payload: obj
        };
        console.log(edit, "=-==-data=-=-=-");
        var resp = await postMethod(edit);
        getData();
        toast(resp.Message)
        // } else {
        //     console.log("error")
        // }
    }

    const deleteBaner = async (data) => {
        var deleteBaner = {
            apiUrl: apiService.deleteBanerSection,
            payload: data
        };
        if (deleteBaner) {
            alert("You want to delete")
            var resp = await postMethod(deleteBaner);
            getData()
            toast(resp.Message)
        }
    }

    const addBaner = () => {
        seteditDetails(false)
        setBanerSection(true)
    }

    const backButton = () => {
        seteditDetails(true)
    }
    return (
        <>
            <PageTitle title="Baner Section" />
            <button className="btn btn-primary custom_btn" onClick={addBaner}>Add</button>

            {editDetails == true ? (

                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th> S.No</th>
                            <th> Baner image </th>
                            <th> Baner contant</th>
                            <th> Baner sub contant</th>
                            <th> Why beleaf </th>
                            <th> Action </th>


                        </tr>
                    </thead>


                    <tbody>
                        {/* {details.map((data, i) => {
                   
                })} */}
                        {details.map((data, i) => (
                            <tr key={data._id}>
                                <td>{i + 1}</td>
                                <td><img style={{
                                    backgroundImage: "url(/background.jpg)",
                                    height: "30px",
                                    backgroundRepeat: "no-repeat"
                                }} src={data.Profile} /></td>
                                <td>{data.Banercontant}</td>
                                <td>{data.Banersubcontant}</td>
                                <td>{data.Whybeleaf}</td>
                                <td onClick={() => editFrom(data)}>
                                    <i class="fa fa-edit"></i>
                                    <a href="javascript:void(0)"></a></td>
                                <td onClick={() => deleteBaner(data)}>
                                    <i class="fa fa-trash" aria-hidden="true"></i>
                                    <a href="javascript:void(0)"></a></td>

                            </tr>
                        ))}

                    </tbody>


                </table>
            ) : (
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <div className="card">
                            <Grid container spacing={4} justify="center">
                                {
                                    blogImage == "" ? <div id="imagePreview"></div> : <img src={blogImage} />
                                }
                                <Grid item xs={5}>
                                    <button className="btn btn-primary custom_btn" onClick={backButton}>Back</button>
                                    {BanerSection == false ? (

                                        <form className="form_padding">
                                            <div className="custom-file">
                                                <label>Baner image</label>
                                                <input
                                                    type="file"
                                                    className="custom-file-input"
                                                    id="customFile"
                                                    onChange={(e) => imageUpload(e.target.files[0])}

                                                />
                                                <label className="custom-file-label" htmlFor="customFile">
                                                    {blogLabel}
                                                </label>
                                            </div>
                                            <div className="form-group">
                                                <label>Baner contant</label>
                                                <input
                                                    type="text"
                                                    name='Banercontant'
                                                    className="form-control"
                                                    placeholder="Baner contant"
                                                    value={Banercontant}
                                                    onChange={getvalue}
                                                />
                                                {BanercontantE == "" ? (
                                                    <p className="text-danger">
                                                        {" "}
                                                        {validationnErr.Banercontant}{" "}
                                                    </p>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label>Baner sub contant</label>
                                                <input
                                                    type="text"
                                                    name='Banersubcontant'
                                                    className="form-control"
                                                    placeholder="Baner sub contant"
                                                    value={Banersubcontant}
                                                    onChange={getvalue}
                                                />
                                                {BanersubcontantE == "" ? (
                                                    <p className="text-danger">
                                                        {" "}
                                                        {validationnErr.Banersubcontant}{" "}
                                                    </p>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label>Why beleaf</label>
                                                <input
                                                    type="text"
                                                    name='Whybeleaf'
                                                    className="form-control"
                                                    placeholder="Why beleaf"
                                                    value={Whybeleaf}
                                                    onChange={getvalue}
                                                />
                                                {WhybeleafE == "" ? (
                                                    <p className="text-danger">
                                                        {" "}
                                                        {validationnErr.Whybeleaf}{" "}
                                                    </p>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <button className="btn btn-primary custom_btn" onClick={editBaner}>Submit</button>
                                        </form>
                                    ) : (
                                        <form className="form_padding">
                                            <div className="custom-file">
                                                <label>Baner image</label>
                                                <input
                                                    type="file"
                                                    className="custom-file-input"
                                                    id="customFile"
                                                    onChange={(e) => imageUpload(e.target.files[0])}

                                                />
                                                <label className="custom-file-label" htmlFor="customFile">
                                                    {blogLabel}
                                                </label>
                                            </div>
                                            <div className="form-group">
                                                <label>Baner contant</label>
                                                <input
                                                    type="text"
                                                    name='Banercontant'
                                                    className="form-control"
                                                    placeholder="Baner contant"
                                                    onChange={getvalue}
                                                />
                                                {BanercontantE == "" ? (
                                                    <p className="text-danger">
                                                        {" "}
                                                        {validationnErr.Banercontant}{" "}
                                                    </p>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label>Baner sub contant</label>
                                                <input
                                                    type="text"
                                                    name='Banersubcontant'
                                                    className="form-control"
                                                    placeholder="Baner sub contant"
                                                    onChange={getvalue}
                                                />
                                                {BanersubcontantE == "" ? (
                                                    <p className="text-danger">
                                                        {" "}
                                                        {validationnErr.Banersubcontant}{" "}
                                                    </p>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div className="form-group">
                                                <label>Why beleaf</label>
                                                <input
                                                    type="text"
                                                    name='Whybeleaf'
                                                    className="form-control"
                                                    placeholder="Why beleaf"
                                                    onChange={getvalue}
                                                />
                                                {WhybeleafE == "" ? (
                                                    <p className="text-danger">
                                                        {" "}
                                                        {validationnErr.Whybeleaf}{" "}
                                                    </p>
                                                ) : (
                                                    ""
                                                )}
                                            </div>

                                            <button className="btn btn-primary custom_btn" onClick={formSubmit}>Submit</button>



                                        </form>
                                    )}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12}></Grid>
                </Grid>

            )}
        </>
    )
}

export default Banersection;