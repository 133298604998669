import React, { useEffect } from "react";
import useState from "react-usestateref";
import PageTitle from "../components/PageTitle/PageTitle";
import { Grid } from "@material-ui/core";
import { toast } from "react-toastify";
import apiService from "../core/service/detail";
import { postMethod, getMethod } from "../core/service/common.api";
import Pagination from "react-js-pagination";
import moment from "moment";
import CKEditor from "react-ckeditor-component";

function Internshipers() {
  const [state, setState] = useState({
    content: "",
  });
  const updateContent = (newContent) => {
    setState({
      content: newContent,
    });
  };
  var [Slug, setSlug, Slugref] = useState("");

  const onChange = (evt) => {
    console.log("onChange fired with event info: ", evt);
    var newContent = evt.editor.getData();
    setState({
      content: newContent,
    });
  };

  const onBlur = (evt) => {
    console.log("onBlur event called with event info: ", evt);
  };

  const afterPaste = (evt) => {
    console.log("afterPaste event called with event info: ", evt);
  };

  var initialvalues = {
    internDepartment: "",
    interntiming: "",
    internPosition: "",
    internDescription: "",
    location: "",
    EducationDetails: "",
  };

  var [inputvalues, setInputvalues] = useState(initialvalues);

  const {
    internDepartment,
    interntiming,
    internPosition,
    internDescription,
    location,
    EducationDetails,
  } = inputvalues;

  useEffect(() => {
    get_intern_details();
  }, []);

  var handleChange = (e) => {
    try {
      e.preventDefault();
      const { name, value } = e.target;
      let datas = { ...inputvalues, ...{ [name]: value } };
      setInputvalues(datas);
      var slug = datas.internPosition
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "");
      setSlug(slug);
    } catch (err) {
      console.log(err, "erro--=--=-=-=-=-=");
    }
  };
  const [interbanner, setInternBanner] = useState();
  const [interbannerName, setInternBannerName] = useState();
  var internBannerUpload = (val) => {
    try {
      console.log("fileExtension===", val.name);

      const fileExtension = val.name.split(".").at(-1);
      const fileSize = val.size;
      const fileName = val.name;
      console.log("fileExtension===", fileExtension);
      console.log("fileSize===", fileSize);
      console.log("fileName===", fileName);
      if (
        fileExtension != "png" &&
        fileExtension != "jpg" &&
        fileExtension != "jpeg" &&
        fileExtension != "webp"
      ) {
        toast.error(
          "File does not support. You must use .png or .jpg or .jpeg ",
        );
        return false;
      } else if (fileSize > 1000000) {
        toast.error("Please upload a file smaller than 1 MB");
        return false;
      } else {
        const data = new FormData();
        data.append("file", val);
        data.append("upload_preset", "ebjirh4i");
        data.append("cloud_name", "blf");
        console.log("formdata===", data);
        fetch("  https://api.cloudinary.com/v1_1/blf/auto/upload", {
          method: "post",
          body: data,
        })
          .then((resp) => resp.json())
          .then((data) => {
            console.log("cloudinary upload===", data);
            setInternBanner(data.secure_url);
            setInternBannerName(fileName);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      console.log(err, "erro--=--=-=-=-=-=");
    }
  };

  var [departmentErr, setdepartmentErr, departmentErrref] = useState(false);
  var [interntimingErr, setinterntimingErr, interntimingErrref] =
    useState(false);
  var [internPositionErr, setinternPositionErr, internPositionErrref] =
    useState(false);
  var [locationErr, setlocationErr, locationErrref] = useState(false);
  var [internDescriptionErr, setinternDescriptionErr, internDescriptionErrref] =
    useState(false);
  var [EducationDetailsErr, setEducationDetailsErr, EducationDetailsErrref] =
    useState(false);
  var [bannerErr, setBannerErr, bannerErrref] = useState(false);
  var [validate, setvalidate] = useState(false);

  const vallidation = (inputvalues) => {
    try {
      var error = {};
      if (inputvalues.internDepartment == "") {
        setdepartmentErr(true);
        error.internDepartment = "Intern Department can't be empty";
      } else {
        setdepartmentErr(false);
      }
      if (inputvalues.interntiming == "") {
        setinterntimingErr(true);
        error.interntiming = "Intern Timing can't be empty";
      } else {
        setinterntimingErr(false);
      }
      if (inputvalues.internPosition == "") {
        setinternPositionErr(true);
        error.internPosition = "Intern Position can't be empty";
      } else {
        setinternPositionErr(false);
      }
      if (inputvalues.internDescription == "") {
        setinternDescriptionErr(true);
        error.internDescription = "Intern Description can't be empty";
      } else {
        setinternDescriptionErr(false);
      }
      if (inputvalues.location == "") {
        setlocationErr(true);
        error.location = "Location can't be empty";
      } else {
        setlocationErr(false);
      }
      if (inputvalues.EducationDetails == "") {
        setEducationDetailsErr(true);
        error.EducationDetails = "Education Details can't be empty";
      } else {
        setEducationDetailsErr(false);
      }
      if (
        interbanner == "" ||
        interbanner == null ||
        interbanner == undefined
      ) {
        error.internBanner = " Intern Banner can't be empty";
        setBannerErr(true);
      } else {
        setBannerErr(false);
      }

      setvalidate(error);
    } catch (err) {
      console.log(err, "err=-=-=-=-=-=-=-=-=-=-=-=-=-=-=");
    }
  };

  const [internpoststatus, setinternpoststatus] = useState("Active");
  const [loader, setLoader] = useState(false);
  const poststatus = (e) => {
    setinternpoststatus(e.target.value);
  };
  const submitInternDetails = async (e) => {
    try {
      e.preventDefault();
      vallidation(inputvalues);
      if (
        departmentErrref.current == false &&
        interntimingErrref.current == false &&
        internPositionErrref.current == false &&
        locationErrref.current == false &&
        internDescriptionErrref.current == false &&
        EducationDetailsErrref.current == false &&
        bannerErrref.current == false
      ) {
        var obj = {
          internDepartment: inputvalues.internDepartment,
          interntiming: inputvalues.interntiming,
          internPosition: inputvalues.internPosition,
          internDescription: inputvalues.internDescription,
          location: inputvalues.location,
          EducationDetails: inputvalues.EducationDetails,
          internBanner: interbanner,
          internpoststatus: internpoststatus,
          content: state.content,
          internslug: Slugref.current,
        };
        setLoader(true);
        var data = {
          apiUrl: apiService.submit_Intern_Details,
          payload: obj,
        };
        var resp = await postMethod(data);
        setLoader(false);
        if (resp.Status == true) {
          get_intern_details();
          toast.success(resp.Message);
          setformstatus(false);
          setState({
            content: "",
          });
        } else {
          toast.error(resp.Message);
        }
      } else {
      }
    } catch (err) {
      console.log(err, "err-0-0-0-err-0-0-0-err-0-0");
    }
  };

  const [viewinterndata, setviewinterndata] = useState(false);
  const [internDetails, setinterndetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalpages] = useState(0);
  const recordPerPage = 5;
  const pageRange = 5;

  const handlePageChange = (pageNumber) => {
    get_intern_details(pageNumber);
    setCurrentPage(pageNumber);
  };

  const get_intern_details = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page,
      };
      var data = {
        apiUrl: apiService.get_Intern_Details,
        payload: payload,
      };
      var resp = await postMethod(data);
      if (resp.Status == true) {
        setinterndetails(resp.data.data);
        setTotalpages(resp.data.total);
      } else {
      }
    } catch (err) {}
  };

  const back = () => {
    setformstatus(false);
  };

  const [itemid, setitemid] = useState();
  const [viewstatus, setviewstatus] = useState(false);
  const view_Intern_Details = async (item) => {
    try {
      setviewstatus(true);
      setformstatus(true);
      console.log(item);
      inputvalues.internDepartment = item.internDepartment;
      inputvalues.interntiming = item.interntiming;
      inputvalues.internPosition = item.internPosition;
      inputvalues.internDescription = item.internDescription;
      inputvalues.location = item.location;
      inputvalues.EducationDetails = item.EducationDetails;
      setInternBanner(item.internBanner);
      setinternpoststatus(item.internpoststatus);
      setitemid(item._id);
      setSlug(item.internslug);

      setState({
        content: item.content,
      });
    } catch (err) {
      console.log(err, "=-=-=---------------=--------------");
    }
  };

  const edit_Intern_Details = async (e) => {
    try {
      e.preventDefault();
      var payload = {
        id: itemid,
        internDepartment: inputvalues.internDepartment,
        interntiming: inputvalues.interntiming,
        internPosition: inputvalues.internPosition,
        internDescription: inputvalues.internDescription,
        location: inputvalues.location,
        EducationDetails: inputvalues.EducationDetails,
        internBanner: interbanner,
        internpoststatus: internpoststatus,
        content: state.content,
        internslug: Slugref.current,
      };
      setLoader(true);
      var data = {
        apiUrl: apiService.edit_Intern_Details,
        payload: payload,
      };
      var resp = await postMethod(data);
      setLoader(false);
      if (resp.Status == true) {
        toast.success(resp.Message);
        setformstatus(false);
        get_intern_details();
      } else {
        toast.error(resp.Message);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const delete_Intern_Details = async (item) => {
    try {
      var payload = {
        id: item._id,
      };
      var data = {
        apiUrl: apiService.delete_Intern_Details,
        payload: payload,
      };
      var resp = await postMethod(data);
      if (resp.Status == true) {
        toast.success(resp.Message);
        get_intern_details();
      } else {
        toast.error(resp.Message);
      }
    } catch (err) {}
  };
  const [formstatus, setformstatus] = useState(false);
  const formstatusChange = () => {
    try {
      setformstatus(true);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {formstatus == true ? (
        <>
          <PageTitle title="Internship Form" />
          <div className="col-md-3 d-block float-right">
            <button className="btn btn-primary" onClick={back}>
              Back
            </button>
          </div>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <div className="card">
                <Grid container spacing={4} justify="center">
                  <Grid item xs={5}>
                    <form className="form_padding">
                      <div className="form-group">
                        <label>Intern Department</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Intern  Department"
                          name="internDepartment"
                          value={inputvalues.internDepartment}
                          onChange={handleChange}
                        />
                        {departmentErr == true ? (
                          <p style={{ color: "red", fontSize: "14px" }}>
                            {validate.internDepartment}
                          </p>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label>Intern Timing</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Intern Timing"
                          name="interntiming"
                          value={inputvalues.interntiming}
                          onChange={handleChange}
                        />
                        {interntimingErr == true ? (
                          <p style={{ color: "red", fontSize: "14px" }}>
                            {validate.interntiming}
                          </p>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label>Intern Position</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Intern Position"
                          name="internPosition"
                          value={inputvalues.internPosition}
                          onChange={handleChange}
                        />
                        {internPositionErr == true ? (
                          <p style={{ color: "red", fontSize: "14px" }}>
                            {validate.internPosition}
                          </p>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label>Intern Description</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Intern Description"
                          name="internDescription"
                          value={inputvalues.internDescription}
                          onChange={handleChange}
                        />
                        {internDescriptionErr == true ? (
                          <p style={{ color: "red", fontSize: "14px" }}>
                            {validate.internDescription}
                          </p>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label>Location</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Location"
                          name="location"
                          value={inputvalues.location}
                          onChange={handleChange}
                        />
                        {locationErr == true ? (
                          <p style={{ color: "red", fontSize: "14px" }}>
                            {validate.location}
                          </p>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label>Education Details</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Education Details"
                          name="EducationDetails"
                          value={inputvalues.EducationDetails}
                          onChange={handleChange}
                        />
                        {EducationDetailsErr == true ? (
                          <p style={{ color: "red", fontSize: "14px" }}>
                            {validate.EducationDetails}
                          </p>
                        ) : null}
                      </div>

                      <div className="form-group ">
                        <label htmlFor="exampleInputPassword1">
                          Intern Banner
                        </label>
                        <div>
                          <input
                            type="file"
                            id="customFile"
                            className="custom-file"
                            name="internbanner"
                            value={inputvalues.interbanner}
                            onChange={(e) =>
                              internBannerUpload(e.target.files[0])
                            }
                          />
                          {bannerErr == true ? (
                            <p style={{ color: "red", fontSize: "14px" }}>
                              {validate.internBanner}
                            </p>
                          ) : null}
                        </div>
                        {interbanner == "" ||
                        interbanner == null ||
                        interbanner == undefined ? (
                          ""
                        ) : (
                          <img width="50" height="50" src={interbanner} />
                        )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">
                          Intern Content
                        </label>

                        <CKEditor
                          activeClass="p10"
                          content={state.content}
                          events={{
                            blur: onBlur,
                            afterPaste: afterPaste,
                            change: onChange,
                          }}
                        />
                      </div>
                      <div class="form-group">
                        <label for="email"> Post Status</label>
                        <div className="copy_icon">
                          <select
                            id="input-gender"
                            className="form-control "
                            name="internpoststatus"
                            value={internpoststatus}
                            onChange={poststatus}
                          >
                            <option value="">Choose</option>
                            <option value="Active">Active</option>
                            <option value="DeActive">DeActive</option>
                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Link</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=" URL"
                          value={Slugref.current}
                          disabled
                        />
                      </div>
                      {viewstatus == false ? (
                        loader == false ? (
                          <button
                            className="btn btn-primary custom_btn"
                            onClick={submitInternDetails}
                          >
                            Sumbit
                          </button>
                        ) : (
                          <button className="btn btn-primary custom_btn">
                            Loading...
                          </button>
                        )
                      ) : loader == false ? (
                        <button
                          className="btn btn-primary custom_btn"
                          onClick={edit_Intern_Details}
                        >
                          Sumbit
                        </button>
                      ) : (
                        <button className="btn btn-primary custom_btn">
                          Loading...
                        </button>
                      )}
                    </form>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <div className="row">
            <div className="col-md-9">
              <PageTitle title="Intership Posts" className="my-3" />
            </div>
            <div className="col-md-3 d-block m-auto">
              <button className="btn btn-primary" onClick={formstatusChange}>
                Add
              </button>
            </div>
          </div>
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <table className="table table-striped table-responsive">
                  <thead>
                    <tr>
                      <th> S.No</th>
                      <th> Department</th>
                      <th> timing </th>
                      <th> Position</th>
                      <th> Description</th>
                      <th> location</th>
                      <th> banner</th>
                      <th> poststatus</th>
                      {/* <th> Action</th> */}
                      <th> Edit</th>
                      <th> Delete</th>
                    </tr>
                  </thead>

                  <tbody>
                    {internDetails && internDetails.length > 0 ? (
                      internDetails.map((item, i) => {
                        return (
                          <tr key={item._id}>
                            <td>{i + 1}</td>
                            <td>{item.internDepartment}</td>
                            <td>{item.interntiming}</td>
                            <td>{item.internPosition}</td>
                            <td>
                              <p>{item.internDescription}</p>
                            </td>
                            <td>{item.location}</td>
                            <td>
                              <img width="50" src={item.internBanner} />
                            </td>

                            <td>{item.internpoststatus}</td>
                            {/* <td
                          // onClick={() => viewemployedetails(item)}
                          >
                            {" "}
                            <i className="fa fa-eye"></i>
                          </td> */}
                            <td
                              className="cursor-pointer"
                              onClick={() => view_Intern_Details(item)}
                            >
                              {" "}
                              <i className="fa fa-edit"></i>
                            </td>
                            <td
                              className="cursor-pointer"
                              onClick={() => delete_Intern_Details(item)}
                            >
                              {""}
                              <i className="fa fa-trash"></i>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td className="colSpan8">No records</td>
                      </tr>
                    )}
                  </tbody>
                </table>
                <div className="row justify-content-end">
                  <Pagination
                    itemClass="page-item"
                    linkClass="page-link"
                    activePage={currentPage}
                    itemsCountPerPage={recordPerPage}
                    totalItemsCount={totalPage}
                    pageRangeDisplayed={pageRange}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Internshipers;
