import React from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import * as Icons from "@material-ui/icons";

// components
import PageTitle from "../components/PageTitle/PageTitle";
// import Widget from "../../components/Widget";
// import Table from "../dashboard/components/Table/Table";

// data
// import mock from "../dashboard/mock";

const datatableData = [
  [
    "Joe James",
    "Example Inc.",
    <Icons.Edit className="cursor-pointer" />,
    <Icons.Delete className="cursor-pointer" />,
  ],
  [
    "Joe James",
    "Example Inc.",
    <Icons.Edit className="cursor-pointer" />,
    <Icons.Delete className="cursor-pointer" />,
  ],
  [
    "Joe James",
    "Example Inc.",
    <Icons.Edit className="cursor-pointer" />,
    <Icons.Delete className="cursor-pointer" />,
  ],
  [
    "Joe James",
    "Example Inc.",
    <Icons.Edit className="cursor-pointer" />,
    <Icons.Delete className="cursor-pointer" />,
  ],
  [
    "Joe James",
    "Example Inc.",
    <Icons.Edit className="cursor-pointer" />,
    <Icons.Delete className="cursor-pointer" />,
  ],
];

export default function Tables() {
  return (
    <>
      <PageTitle title="Page Meta" />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <div className="card">
            <Grid container spacing={4} justify="center">
              <Grid item xs={5}>
                <form className="form_padding">
                  <div class="form-group">
                    <label>Mata Title</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Mata Title"
                    />
                  </div>
                  <div class="form-group">
                    <label for="exampleInputPassword1">Meta Description</label>
                    <textarea rows="5" class="form-control"></textarea>
                  </div>
                  <div class="form-group">
                    <label>Key Word</label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Key Word"
                    />
                  </div>
                  <div class="form-group mb-4">
                    <label for="exampleInputPassword1">OG Image</label>
                    <div class="custom-file">
                      <input
                        type="file"
                        class="custom-file-input"
                        id="customFile"
                      />
                      <label class="custom-file-label" for="customFile">
                        Choose file
                      </label>
                    </div>
                  </div>

                  <button type="submit" class="btn btn-primary custom_btn">
                    Submit
                  </button>
                </form>
              </Grid>
              <Grid item xs={12}>
                <MUIDataTable
                  title="Category Name"
                  className="none-shodow"
                  data={datatableData}
                  columns={["Category Name", "URL", "Edit", "Delete"]}
                  options={{
                    filterType: "checkbox",
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </>
  );
}
