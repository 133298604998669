import React, { useEffect } from "react";
import useState from 'react-usestateref';
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import PageTitle from "../components/PageTitle/PageTitle";
import apiService from "../core/service/detail";
import { getMethod } from "../core/service/common.api";

function Bookdownloaderlist() {


  const [downloaderlist,setDownloaderList]=useState([])

    useEffect(() => {
        getDownloaderList();
     }, [0])
     
  const getDownloaderList=async()=>{
       var data={
         apiUrl: apiService.downloaderList,
       }
       var resp=await getMethod(data);
       console.log(resp.Message.downloader_Details,"respresp")
       setDownloaderList(resp.Message.downloader_Details)
     }
       

  return (
    <>
     <PageTitle title=" Book  DownloaderList" />
    <Grid item xs={12}>
        <MUIDataTable
            data={downloaderlist}
            columns={["DownloaderName","DownloaderNumber", "DownloaderCountry","DownloderSocialId","DownoaderMail","BookId","DonwlodedTime"]}
        />
    </Grid>
    </>
  )
}

export default Bookdownloaderlist