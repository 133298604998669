import React, { useEffect } from "react";
import useState from "react-usestateref";
import Pagination from "react-js-pagination";
import apiService from "../core/service/detail";
import { getMethod } from "../core/service/common.api";
import { postMethod } from "../core/service/common.api";
import moment from "moment";
import PdfImage from "../images/PDF_file_icon.svg.png";

function Jobapplyerlist() {
  const [currentPage, setCurrentPage] = useState(1);
  const [jobApplyers, setjobApplyers, jobApplyersref] = useState([]);
  const [totalPage, setTotalpages] = useState(0);

  const recordPerPage = 5;
  // total number of the records
  const totalRecords = 15;
  // range of pages in paginator
  const pageRange = 5;

  const handlePageChange = (pageNumber) => {
    getJobApplyers(pageNumber);
    setCurrentPage(pageNumber);
    // setchoosepage()
  };

  useEffect(() => {
    getJobApplyers();
  }, []);

  const getJobApplyers = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page,
      };
      var data = {
        apiUrl: apiService.get_beleaf_Job_applyers,
        payload: payload,
      };
      var resp = await postMethod(data);
      if (resp.status) {
        console.log(resp.data.data, "resp.data.data");
        setjobApplyers(resp.data.data);
        setTotalpages(resp.data.total);
      }
    } catch (error) {}
  };

  const enquiry = async (e) => {
    try {
      var payload = {
        _id: e._id,
        status: "read",
      };
      var data = {
        apiUrl: apiService.changestatus,
        payload: payload,
      };

      var resp = await postMethod(data);
      if (resp.status) {
        getJobApplyers();
      } else {
      }
    } catch (error) {
      console.log("=-=-=-=-=-=-=-=-", error, "=-=-=-=-=-error-=-=");
    }
  };

  return (
    <div>
      {" "}
      Beleaf Job Applyers List
      <table class="table table-striped">
        <thead>
          <tr>
            <th> S.No</th>
            <th> Name </th>
            <th> Email</th>
            <th> Department</th>
            <th> Position</th>
            <th> Mobile Number</th>
            <th> Resume </th>
            <th> createdAt </th>
            <th> Status </th>
          </tr>
        </thead>

        <tbody>
          {jobApplyersref.current &&
            jobApplyersref.current.map((orders, i) => {
              return (
                <tr>
                  <td>{i + 1}</td>
                  <td>{orders.firstname}</td>
                  <td>{orders.Email}</td>
                  <td>{orders.jobDepartment}</td>
                  <td>{orders.jobPosition}</td>
                  <td>{orders.mobileNumber}</td>
                  <td>
                      <img style= {{"width":"20px"}} src= {PdfImage} /> 
                      <a href={orders.resume} target="_blank">  View </a>
                        
                    </td>
                  <td>{moment(orders.createdAt).format("lll")}</td>

                  <td>
                    {orders.readStatus == "notread" ? (
                      <p
                        style={{ color: "green" }}
                        onClick={() => enquiry(orders)}
                      >
                        {" "}
                        Open{" "}
                      </p>
                    ) : (
                      <p style={{ color: "red" }}> Closed </p>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <Pagination
        itemClass="page-item" // add it for bootstrap 4
        linkClass="page-link" // add it for bootstrap 4
        activePage={currentPage}
        itemsCountPerPage={recordPerPage}
        totalItemsCount={totalPage}
        pageRangeDisplayed={pageRange}
        onChange={handlePageChange}
      />
    </div>
  );
}

export default Jobapplyerlist;
