import React, { useEffect } from "react";
import useState from 'react-usestateref';
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import * as Icons from "@material-ui/icons";
import { useUserDispatch, saveService, updateService } from "../context/ServiceContext";
import apiService from "../core/service/detail";
import { getMethod } from "../core/service/common.api";
import { postMethod } from "../core/service/common.api";
// import { PdfImage } from '../images/PDF_file_icon.svg.png'
import PdfImage from "../images/PDF_file_icon.svg.png";
import Pagination from "react-js-pagination";

// components
import PageTitle from "../components/PageTitle";

import moment from "moment";
import { toast } from "react-toastify";

const SPACED_DATE_FORMAT = "yyyy-MM-DD HH:mm:ss.SSS";

export default function ServiceList(props) {
  var [addService, setaddService] = useState(false)
  var [categoryNameList, setcategoryNameList, categoryNameListref] = useState([])
  var [blogLabel, setblogLabel] = useState("Icon upload");
  var [ImageUpload, setImageUpload] = useState("Background upload");
  const [iconImage, seticonImage] = useState("")
  const [backImage, setbackImage] = useState("")
  const [headingE, setheadingE] = useState(false)
  const [ShortcontentE, setShortcontentE] = useState(false)
  const [FullcontentE, setFullcontentE] = useState(false)
  const [iconImageE, seticonImageE] = useState(false)
  const [backImageE, setbackImageE] = useState(false)
  const [validationnErr, setvalidationnErr] = useState(false)


  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalpages] = useState(0);
  const [editDetails, seteditDetails] = useState(false)
  const [idvalue, setidvalue] = useState("")

  const recordPerPage = 5;
  const totalRecords = 15;
  const pageRange = 5;

  const values = {
    Heading: "",
    Shortcontent: "",
    Fullcontent: "",
  }

  const [formValue, setFormValue] = useState(values)
  const handlePageChange = pageNumber => {
    getcategoryNameList(pageNumber)
    setCurrentPage(pageNumber);
    // setchoosepage()
  }
  const {
    Heading,
    Shortcontent,
    Fullcontent
  } = formValue

  useEffect(() => {
    async function fetchData() {
      getcategoryNameList();
    }
    fetchData();
  }, []);

  const getcategoryNameList = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page
      }
      var data = {
        apiUrl: apiService.get_blog_name_list,
        payload: payload
      };
      var resp = await postMethod(data);
      if (resp.status) {
        setcategoryNameList(resp.data.data);
        setTotalpages(resp.data.total);
      }
    } catch (error) {

    }
  }
  const addservice = async (e) => {
    if (e && e.preventDefault) { e.preventDefault(); }

    if (backImage == "") {
      console.log("error")
    } else {
      formValue["backImage"] = backImage
    }
    if (iconImage == "") {
      console.log("error")
    } else {
      formValue["iconImage"] = iconImage
    }
    validation()
    if (formValue.Heading != "" && formValue.Shortcontent != "" && formValue.Fullcontent != "") {
      var data = {
        apiUrl: apiService.saveService,
        payload: formValue
      };
      var resp = await postMethod(data);
      toast(resp.Message)
      getcategoryNameList()
      setaddService(false)
    } else {

    }
  }
  const validation = () => {
    const error = {}
    try {
      if (Heading == "") {
        console.log("view")
        error.Heading = "Heading is a required field";
        setheadingE(true);
      }
      if (Shortcontent == "") {
        error.Shortcontent = "Shortcontent is a required field";
        setShortcontentE(true);
      }
      if (Fullcontent == "") {
        error.Fullcontent = "Fullcontent is a required field";
        setFullcontentE(true);
      }
      if (backImage == "") {
        error.iconImage = "Background image is a required field";
      }
      if (iconImage == "") {
        error.iconImage = "icon is a required field";
      }
      setvalidationnErr(error);
      return error;
    } catch (error) {
      console.log(error, "==-=-=-=error=-=-=-")
    }
  }
  const getValues = (e) => {
    if (e && e.preventDefault) { e.preventDefault(); }
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } };
    setFormValue(formData);
    console.log(formValue, "=-=-=-===-=-")

  }


  const iconUpload = (val) => {
    console.log("val===", val)
    const fileExtension = val.name.split(".").at(-1);
    const fileSize = val.size;
    const fileName = val.name;
    console.log("fileExtension===", fileExtension);
    console.log("fileSize===", fileSize);
    console.log("fileName===", fileName);
    if (fileExtension != "png" && fileExtension != "jpg" && fileExtension != "jpeg") {
      // toast.error("File does not support. You must use .png or .jpg or .jpeg ");
      return false;
    }
    else if (fileSize > 1000000) {
      //   toast.error("Please upload a file smaller than 1 MB");
      return false;
    }
    else {
      const data = new FormData()
      data.append("file", val)
      data.append("upload_preset", "ebjirh4i")
      data.append("cloud_name", "blf")
      console.log("formdata===", data)
      fetch("  https://api.cloudinary.com/v1_1/blf/auto/upload", {
        method: "post",
        body: data
      })
        .then(resp => resp.json())
        .then(data => {
          console.log("cloudinary upload===", data);
          setbackImage(data.secure_url);
          setblogLabel(fileName);
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
  const imageUpload = (val) => {
    console.log("val===", val)
    const fileExtension = val.name.split(".").at(-1);
    const fileSize = val.size;
    const fileName = val.name;
    console.log("fileExtension===", fileExtension);
    console.log("fileSize===", fileSize);
    console.log("fileName===", fileName);
    if (fileExtension != "png" && fileExtension != "jpg" && fileExtension != "jpeg") {
      // toast.error("File does not support. You must use .png or .jpg or .jpeg ");
      return false;
    }
    else if (fileSize > 1000000) {
      //   toast.error("Please upload a file smaller than 1 MB");
      return false;
    }
    else {
      const data = new FormData()
      data.append("file", val)
      data.append("upload_preset", "ebjirh4i")
      data.append("cloud_name", "blf")
      console.log("formdata===", data)
      fetch("  https://api.cloudinary.com/v1_1/blf/auto/upload", {
        method: "post",
        body: data
      })
        .then(resp => resp.json())
        .then(data => {
          console.log("cloudinary upload===", data);
          seticonImage(data.secure_url);
          setImageUpload(fileName);
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
  const editdata = async (data) => {

    var edit = {
      apiUrl: apiService.editDatas,
      payload: data
    };
    var responce = await postMethod(edit);
    if (responce.Status == true) {
      console.log(responce.data._id)
      formValue.Heading = responce.data.Heading
      formValue.Shortcontent = responce.data.Shortcontent
      formValue.Fullcontent = responce.data.Fullcontent
      setaddService(true)
      setbackImage(responce.data.IconImage)
      seticonImage(responce.data.BackImage)
      seteditDetails(false)
      setidvalue(responce.data._id)
      getcategoryNameList()
    } else {
    }

  }
  const deleteDetails = async (data) => {
    alert("are you sure")
    var del = {
      apiUrl: apiService.deleteDatas,
      payload: data
    };
    var resp = await postMethod(del);
    toast(resp.Message)
    getcategoryNameList()

  }
  const editBaner = async (e) => {
    if (e && e.preventDefault) { e.preventDefault(); }
    var obj = {
      Heading: formValue.Heading,
      Shortcontent: formValue.Shortcontent,
      Fullcontent: formValue.Fullcontent,
      iconImage: iconImage,
      backImage: backImage,
      _id: idvalue

    };
    var edit = {
      apiUrl: apiService.editService,
      payload: obj
    };
    console.log(edit, "=-==-data=-=-=-");
    var resp = await postMethod(edit);
    setaddService(false)

  }
  const adddata = () => {
    setaddService(true)
    seteditDetails(true)
  }
  const Bacefunction = () => {
    setaddService(false)
  }
  return (
    <>

      <PageTitle title="Service List" />
      <button className="btn btn-primary custom_btn" onClick={adddata}>Add</button>
      {addService == false ? (
        <div>


          <table class="table table-striped">
            <thead>
              <tr>
                <th> S.No</th>
                <th> Icon </th>
                <th> Heading </th>
                <th> Short Content </th>
                <th> Full Content </th>
                <th> Date </th>
                <th>Background Image</th>
                <th> Action </th>

              </tr>
            </thead>

            <tbody>
              {
                categoryNameListref.current && categoryNameListref.current.map((categories, i) => {

                  return (
                    <tr>
                      <td>
                        {i + 1}
                      </td>
                      <td>
                        <img style={{
                          backgroundImage: "url(/background.jpg)",
                          height: "30px",
                          backgroundRepeat: "no-repeat"
                        }} src={categories.IconImage} />
                      </td>
                      <td>
                        {categories.Heading}
                      </td>
                      <td>
                        {categories.Shortcontent}
                      </td>
                      <td>
                        {categories.Fullcontent}
                      </td>
                      <td>
                      {moment(categories.createdAt).format("lll")}
                        
                      </td>
                      <td>
                        <img style={{
                          backgroundImage: "url(/background.jpg)",
                          height: "30px",
                          backgroundRepeat: "no-repeat"
                        }} src={categories.BackImage} />
                      </td>
                      <td onClick={() => editdata(categories)}>
                        <i class="fa fa-edit"></i>
                        <a href="javascript:void(0)"></a>

                      </td>
                      <td onClick={() => deleteDetails(categories)}>
                        <i class="fa fa-trash"></i>
                        <a href="javascript:void(0)"></a>

                      </td>
                    </tr>
                  )
                })
              }

            </tbody>

          </table>

          {/* <Pagination
            itemClass="page-item" // add it for bootstrap 4
            linkClass="page-link" // add it for bootstrap 4
            activePage={currentPage}
            itemsCountPerPage={recordPerPage}
            totalItemsCount={totalPage}
            pageRangeDisplayed={pageRange}
            onChange={handlePageChange}
          /> */}
        </div>
      ) : (

        <Grid container spacing={4}>
          <Grid item xs={12}>
            <div className="card">
              <Grid container spacing={4} justify="center">
                <lable>Icon Image</lable>
                {
                  backImage == "" ? <div id="imagePreview"></div> : <img src={backImage} />
                }
                <lable>Back ground Image</lable>
                {
                  iconImage == "" ? <div id="imagePreview"></div> : <img src={iconImage} />
                }
                <Grid item xs={5}>
                  <button className="btn btn-primary custom_btn" onClick={Bacefunction}>Back</button>
                  {editDetails == true ? (

                    <form className="form_padding">
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFile"
                          onChange={(e) => iconUpload(e.target.files[0])}

                        /><label className="custom-file-label" htmlFor="customFile">
                          {blogLabel}
                        </label>
                        {iconImageE == "" ? (
                          <p className="text-danger">
                            {" "}
                            {validationnErr.iconImage}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group">
                        <label>Heading</label>
                        <input
                          type="text"
                          name="Heading"
                          className="form-control"
                          placeholder="Heading"
                          // value={categoryNameValue}
                          onChange={getValues}
                        />
                        {headingE == true ? (
                          <p className="text-danger">
                            {" "}
                            {validationnErr.Heading}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group">
                        <label>Short content</label>
                        <input
                          type="text"
                          name="Shortcontent"
                          className="form-control"
                          placeholder="Short content"
                          // value={categoryNameValue}
                          onChange={getValues}
                        />
                        {ShortcontentE == true ? (
                          <p className="text-danger">
                            {" "}
                            {validationnErr.Shortcontent}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFile"
                          onChange={(e) => imageUpload(e.target.files[0])}

                        />
                        <label className="custom-file-label" htmlFor="customFile">
                          {ImageUpload}
                        </label>
                        {backImageE == true ? (
                          <p className="text-danger">
                            {" "}
                            {validationnErr.backImage}{" "}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="form-group">
                        <label>Full content</label>
                        <input
                          type="text"
                          name="Fullcontent"
                          className="form-control"
                          placeholder="Full content"
                          // value={categoryNameValue}
                          onChange={getValues}
                        />
                      </div>
                      {FullcontentE == true ? (
                        <p className="text-danger">
                          {" "}
                          {validationnErr.Fullcontent}{" "}
                        </p>
                      ) : (
                        ""
                      )}
                      <button
                        onClick={addservice}
                        className="btn btn-primary custom_btn">
                        Submit
                      </button>
                    </form>
                  ) : (
                    <form className="form_padding">

                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFile"
                          onChange={(e) => iconUpload(e.target.files[0])}

                        />
                        <label className="custom-file-label" htmlFor="customFile">
                          {blogLabel}
                        </label>
                      </div>
                      <div className="form-group">
                        <label>Heading</label>
                        <input
                          type="text"
                          name="Heading"
                          className="form-control"
                          placeholder="Heading"
                          value={Heading}
                          onChange={getValues}
                        />
                      </div>
                      <div className="form-group">
                        <label>Short content</label>
                        <input
                          type="text"
                          name="Shortcontent"
                          className="form-control"
                          placeholder="Short content"
                          value={Shortcontent}
                          onChange={getValues}
                        />
                      </div>
                      <div className="custom-file">
                        <input
                          type="file"
                          className="custom-file-input"
                          id="customFile"
                          onChange={(e) => iconUpload(e.target.files[0])}

                        />
                        <label className="custom-file-label" htmlFor="customFile">
                          {ImageUpload}
                        </label>
                      </div>
                      <div className="form-group">
                        <label>Full content</label>
                        <input
                          type="text"
                          name="Fullcontent"
                          className="form-control"
                          placeholder="Full content"
                          value={Fullcontent}
                          onChange={getValues}
                        />
                      </div>
                      <button
                        onClick={editBaner}
                        className="btn btn-primary custom_btn">
                        Submit
                      </button>

                    </form>
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      )}


    </>
  );
}
