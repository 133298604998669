//=====================================JOTHI

import React, { useEffect } from "react";
import useState from "react-usestateref";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import * as Icons from "@material-ui/icons";
import { useUserDispatch, saveBlog, updateBlog } from "../context/BlogContext";
import apiService from "../core/service/detail";
import { getMethod } from "../core/service/common.api";
import { postMethod } from "../core/service/common.api";
// import { PdfImage } from '../images/PDF_file_icon.svg.png'
import PdfImage from "../images/PDF_file_icon.svg.png";
import Pagination from "react-js-pagination";
import moment from "moment";
import PageTitle from "../components/PageTitle";
import { Country, State, City } from "country-state-city";
import useStateRef from "react-usestateref";
import cloudinary from "cloudinary/lib/v2";
import { toast } from "react-toastify";

export default function Tables(props) {
  //INPUTDATA PART===================================
  const inputvalues = {
    firstName: "",
    lastName: "",
    fathersName: "",
    dateofBirth: "",
    bloodGroup: "",
    dateofJoin: "",
    nationality: "",
    dateofLeave: "",
    mail: "",
    personalMail: "",
    phone: "",
    emergencyPhone: "",
    address1: "",
    address2: "",
    zip: "",
    salary: "",
    designation: "",
    policy: "",
    reasonofresign: "",
    note: "",
    documentName: "",
    documentType: "",
    employeID: "",
    contract: "",
    document: "",
    id: "",
  };

  const [formValue, setFormvalue] = useState(inputvalues);
  const {
    firstName,
    lastName,
    fathersName,
    dateofBirth,
    bloodGroup,
    dateofJoin,
    nationality,
    dateofLeave,
    mail,
    personalMail,
    phone,
    emergencyPhone,
    address1,
    address2,
    zip,
    salary,
    designation,
    policy,
    reasonofresign,
    note,
    documentName,
    documentType,
    employeID,
    contract,
    document,
    id,
  } = formValue;

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let datas = { ...formValue, ...{ [name]: value } };
    setFormvalue(datas);
  };
  //ACTIVE TAB========================================
  const [activeButton, setActiveButton] = useState("Personal");

  const setActive = (buttonId) => {
    setActiveButton(buttonId);
  };

  //SELECTDEPARTMENT======================================
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const handleDepartmentChange = (event) => {
    setSelectedDepartment(event.target.value);
  };

  const Department = [
    { key: "Developement", label: "Developement", value: "Developement" },
    { key: "Designer", label: "Designer", value: "Designer" },
    {
      key: "Digital Marketting",
      label: "Digital Marketting",
      value: "Digital Marketting",
    },
    { key: "MobileApp", label: "MobileApp", value: "MobileApp" },
    { key: "Chiefs", label: "Chiefs", value: "Chiefs" },
    { key: "Director", label: "Director", value: "Director" },
  { key: "Human resource management", label: "Human resource management", value: "Human resource management" },

    {
      key: "Bussiness Excutivers",
      label: "Bussiness Excutivers",
      value: "Bussiness Excutivers",
    },
  ];

  //CSC USING===============================

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("state");
  const [selectedCity, setSelectedCity] = useState("city");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const result = await Country.getAllCountries();
        let allCountries = [];
        allCountries = result?.map(({ isoCode, name }) => ({
          isoCode,
          name,
        }));
        const [{ name: firstCountry } = {}] = allCountries;
        setCountries(allCountries);
        setSelectedCountry(firstCountry);
      } catch (error) {
        setCountries([]);
      }
    };
    getCountries();
  }, []);
  useEffect(() => {
    const getStates = async () => {
      try {
        const result = await State.getStatesOfCountry(selectedCountry);
        let allStates = [];
        allStates = result?.map(({ isoCode, name }) => ({
          isoCode,
          name,
        }));
        const [{ isoCode: firstState = "" } = {}] = allStates;
        setCities([]);
        setSelectedCity("");
        setStates(allStates);
        setSelectedState(firstState);
      } catch (error) {
        setStates([]);
        setCities([]);
        setSelectedCity("");
      }
    };
    getStates();
  }, [selectedCountry]);
  useEffect(() => {
    const getCities = async () => {
      try {
        const result = await City.getCitiesOfState(
          selectedCountry,
          selectedState,
        );
        let allCities = [];
        allCities = result?.map(({ name }) => ({
          name,
        }));
        const [{ name: firstCity = "" } = {}] = allCities;
        setCities(allCities);
        setSelectedCity(firstCity);
      } catch (error) {
        setCities([]);
      }
    };
    getCities();
  }, [selectedState]);

  //GENDERSELECT================================================
  const [SelectGender, setSelectGender] = useState("");

  //WORKERSTATUS
  const [Workerstatus, setWorkerstatus] = useState("");

  //VALLIDATION PART=================================================

  const [vallidation, setvallidation] = useState("");
  const [CityErr, setCityErr, CityErrref] = useStateRef(false);
  const [stateErr, setstateErr, stateErrref] = useStateRef(false);
  const [showstatusErr, setshowstatusErr, showstatusErrref] =
    useStateRef(false);
  const [countryErr, setcountryErr, countryErrref] = useStateRef(false);
  const [firstNameErr, setfirstNameErr, firstNameErrref] = useStateRef(false);
  const [lastNameErr, setlastNameErr, lastNameErrref] = useStateRef(false);
  const [fathersNameeErr, setfathersNameeErr, fathersNameeErrref] =
    useStateRef(false);
  const [dateofBirthErr, setdateofBirthErr, dateofBirthErrref] =
    useStateRef(false);
  const [bloodGroupErr, setbloodGroupErr, bloodGroupErrref] =
    useStateRef(false);
  const [dateofJoinErr, setdateofJoinErr, dateofJoinErrref] =
    useStateRef(false);
  const [nationalityErr, setnationalityErr, nationalityErrref] =
    useStateRef(false);
  const [dateofLeaveErr, setdateofLeaveErr, dateofLeaveErrref] =
    useStateRef(false);
  const [mailErr, setmailErr, mailErrref] = useStateRef(false);
  const [personalMailErr, setpersonalMailErr, personalMailErrref] =
    useStateRef(false);
  const [phoneErr, setphoneErr, phoneErrref] = useStateRef(false);
  const [emergencyPhoneErr, setemergencyPhoneErr, emergencyPhoneErrref] =
    useStateRef(false);
  const [address1Err, setaddress1Err, address1Errref] = useStateRef(false);
  const [address2Err, setaddress2Err, address2Errref] = useStateRef(false);
  const [zipErr, setzipErr, zipErrref] = useStateRef(false);
  const [salaryErr, setsalaryErr, salaryErrref] = useStateRef(false);
  const [designationErr, setdesignationErr, designationErrref] =
    useStateRef(false);
  const [policyErr, setpolicyErr, policyErrref] = useStateRef(false);
  const [reasonofresignErr, setreasonofresignErr, reasonofresignErrref] =
    useStateRef(false);
  const [noteErr, setnoteErr, noteErrref] = useStateRef(false);
  const [documentTypeErr, setdocumentTypeErr, documentTypeErrref] =
    useStateRef(false);
  const [documentNameErr, setdocumentNameErr, documentNameErrref] =
    useStateRef(false);
  const [GenderErr, setGenderErr, GenderErrref] = useStateRef(false);
  const [departmentErr, setdepartmentErr, departmentErrref] =
    useStateRef(false);
  const [contractErr, setcontractErr, contractErrref] = useStateRef(false);
  const [documentErr, setdocumentErr, documentErrref] = useStateRef(false);
  const [employeIDErr, setemployeIDErr, employeIDErrref] = useStateRef(false);

  const vallidatepart1 = () => {
    try {
      var error = {};
      if (formValue.firstName == "") {
        setfirstNameErr(true);
        error.firstName = "I Need This Field !";
      } else {
        setfirstNameErr(false);
      }
      if (formValue.lastName == "") {
        setlastNameErr(true);
        error.lastName = "I Need This Field !";
      } else {
        setlastNameErr(false);
      }
      if (formValue.fathersName == "") {
        setfathersNameeErr(true);
        error.fathersName = "I Need This Field !";
      } else {
        setfathersNameeErr(false);
      }
      if (formValue.personalMail == "") {
        setpersonalMailErr(true);
        error.personalMail = "I Need This Field !";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formValue.personalMail)
      ) {
        setpersonalMailErr(true);
        error.personalMail = "Invalid Field !";
      } else {
        setpersonalMailErr(false);
      }
      if (formValue.dateofBirth == "") {
        setdateofBirthErr(true);
        error.dateofBirth = "I Need This Field !";
      } else {
        setdateofBirthErr(false);
      }

      if (formValue.nationality == "") {
        setnationalityErr(true);
        error.nationality = "I Need This Field !";
      } else {
        setnationalityErr(false);
      }
      // if (formValue.personalMail == "") {
      //   setpersonalMailErr(true);
      //   error.personalMail = "I Need This Field !";
      // } else {
      //   setpersonalMailErr(false);
      // }
      if (formValue.phone == "") {
        setphoneErr(true);
        error.phone = "I Need This Field !";
      } else {
        setphoneErr(false);
      }

      // if (formValue.phone == "") {
      //   setphoneErr(true);
      //   error.phone = "I Need This Field !";
      // } else {
      //   setphoneErr(false);
      // }
      if (formValue.address1 == "") {
        setaddress1Err(true);
        error.address1 = "I Need This Field !";
      } else {
        setaddress1Err(false);
      }
      if (formValue.address2 == "") {
        setaddress2Err(true);
        error.address2 = "I Need This Field !";
      } else {
        setaddress2Err(false);
      }
      if (formValue.zip == "") {
        setzipErr(true);
        error.zip = "I Need This Field !";
      } else {
        setzipErr(false);
      }
      if (
        SelectGender == "" ||
        SelectGender == null ||
        SelectGender == undefined
      ) {
        setGenderErr(true);
        error.gender = "I Need This Field !";
      } else {
        setGenderErr(false);
      }
      if (
        selectedCountry == "" ||
        selectedCountry == null ||
        selectedCountry == undefined
      ) {
        setcountryErr(true);
        error.gender = "I Need This Field !";
      } else {
        setcountryErr(false);
      }

      if (
        selectedCity == "" ||
        selectedCity == null ||
        selectedCity == undefined
      ) {
        setCityErr(true);
        error.City = "I Need This Field !";
      } else {
        setCityErr(false);
      }

      if (
        selectedState == "" ||
        selectedState == null ||
        selectedState == undefined
      ) {
        setstateErr(true);
        error.State = "I Need This Field !";
      } else {
        setstateErr(false);
      }

      setvallidation(error);
    } catch (error) {}
  };

  const vallidatepart2 = () => {
    try {
      var error = {};
      if (formValue.dateofJoin == "") {
        setdateofJoinErr(true);
        error.dateofJoin = "I Need This Field !";
      } else {
        setdateofJoinErr(false);
      }
      if (formValue.dateofLeave == "") {
        setdateofLeaveErr(true);
        error.dateofLeave = "I Need This Field !";
      } else {
        setdateofLeaveErr(false);
      }
      if (formValue.emergencyPhone == "") {
        setemergencyPhoneErr(true);
        error.emergencyPhone = "I Need This Field !";
      } else {
        setemergencyPhoneErr(false);
      }
      if (formValue.bloodGroup == "") {
        setbloodGroupErr(true);
        error.bloodGroup = "I Need This Field !";
      } else {
        setbloodGroupErr(false);
      }

      if (formValue.mail == "") {
        setmailErr(true);
        error.mail = "I Need This Field !";
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formValue.mail)
      ) {
        setmailErr(true);
        error.mail = "Invalid Field !";
      } else {
        setmailErr(false);
      }
      if (formValue.employeID == "") {
        setemployeIDErr(true);
        error.employeID = "I Need This Field !";
      } else {
        setemployeIDErr(false);
      }
      if (formValue.salary == "") {
        setsalaryErr(true);
        error.salary = "I Need This Field !";
      } else {
        setsalaryErr(false);
      }
      if (formValue.designation == "") {
        setdesignationErr(true);
        error.designation = "I Need This Field !";
      } else {
        setdesignationErr(false);
      }
      if (formValue.policy == "") {
        setpolicyErr(true);
        error.policy = "I Need This Field !";
      } else {
        setpolicyErr(false);
      }
      if (formValue.reasonofresign == "") {
        setreasonofresignErr(true);
        error.reasonofresign = "I Need This Field !";
      } else {
        setreasonofresignErr(false);
      }
      if (formValue.note == "") {
        setnoteErr(true);
        error.note = "I Need This Field !";
      } else {
        setnoteErr(false);
      }
      if (
        selectedDepartment == "" ||
        selectedDepartment == null ||
        selectedDepartment == undefined
      ) {
        setdepartmentErr(true);
        error.department = "I Need This Field !";
      } else {
        setdepartmentErr(false);
      }
      setvallidation(error);
    } catch (error) {}
  };
  const vallidatepart4 = () => {
    try {
      var error = {};
      if (formValue.documentName == "") {
        setdocumentNameErr(true);
        error.documentName = "I Need This Field !";
      } else {
        setdocumentNameErr(false);
      }
      if (formValue.documentType == "") {
        setdocumentTypeErr(true);
        error.documentType = "I Need This Field !";
      } else {
        setdocumentTypeErr(false);
      }
      setvallidation(error);
    } catch (error) {}
  };
  //SUBMIT FUNCTION =========================================================

  const next1 = async () => {
    try {
      vallidatepart1(formValue);
      console.log(firstNameErrref.current == false);
      console.log(lastNameErrref.current == false);
      console.log(fathersNameeErrref.current == false);
      console.log(dateofBirthErrref.current == false);
      console.log(nationalityErrref.current == false);
      console.log(personalMailErrref.current == false);
      console.log(phoneErrref.current == false);
      console.log(address1Errref.current == false);
      console.log(address2Errref.current == false);
      console.log(countryErrref.current == false);
      console.log(zipErrref.current == false);
      console.log(GenderErrref.current == false);
      if (
        firstNameErrref.current == false &&
        lastNameErrref.current == false &&
        fathersNameeErrref.current == false &&
        dateofBirthErrref.current == false &&
        nationalityErrref.current == false &&
        personalMailErrref.current == false &&
        phoneErrref.current == false &&
        address1Errref.current == false &&
        address2Errref.current == false &&
        zipErrref.current == false &&
        GenderErrref.current == false &&
        countryErrref.current == false
      ) {
        setActive("Work");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const next2 = async () => {
    try {
      vallidatepart2(formValue);
      console.log(dateofJoinErrref.current == false);
      console.log(dateofLeaveErrref.current == false);
      console.log(emergencyPhoneErrref.current == false);
      console.log(bloodGroupErrref.current == false);
      console.log(mailErrref.current == false);
      console.log(employeIDErrref.current == false);
      console.log(salaryErrref.current == false);
      console.log(designationErrref.current == false);
      console.log(policyErrref.current == false);
      console.log(reasonofresignErrref.current == false);
      console.log(departmentErrref.current == false);
      if (
        dateofJoinErrref.current == false &&
        dateofLeaveErrref.current == false &&
        emergencyPhoneErrref.current == false &&
        bloodGroupErrref.current == false &&
        mailErrref.current == false &&
        employeIDErrref.current == false &&
        salaryErrref.current == false &&
        designationErrref.current == false &&
        policyErrref.current == false &&
        departmentErrref.current == false &&
        reasonofresignErrref.current == false
      ) {
        var employeDetails = {
          firstName: formValue.firstName,
          lastName: formValue.lastName,
          fathersName: formValue.fathersName,
          dateofBirth: formValue.dateofBirth,
          bloodGroup: formValue.bloodGroup,
          dateofJoin: formValue.dateofJoin,
          nationality: formValue.nationality,
          dateofLeave: formValue.dateofLeave,
          mail: formValue.mail,
          personalMail: formValue.personalMail,
          phone: formValue.phone,
          emergencyPhone: formValue.emergencyPhone,
          address1: formValue.address1,
          address2: formValue.address2,
          zip: formValue.zip,
          salary: formValue.salary,
          designation: formValue.designation,
          policy: formValue.policy,
          reasonofresign: formValue.reasonofresign,
          note: formValue.note,
          documentName: formValue.documentName,
          documentType: formValue.documentType,
          employeID: formValue.employeID,
          contract: formValue.contract,
          document: formValue.document,
          gender: SelectGender,
          country: selectedCountry,
          city: selectedCity,
          state: selectedState,
          department: selectedDepartment,
        };
        var data = {
          apiUrl: apiService.submit_Employee_details,
          payload: employeDetails,
        };
        var resp = await postMethod(data);
        if (resp.status == true) {
          toast.success(resp.messag);
          get_employe_details();
          setviewemployedata(false);

          // setviewingemployedtailsstatus(true);
        } else {
          toast.error(resp.messag);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  // const next4 = async () => {
  //   vallidatepart4(formValue);
  // };

  //  GETEMPLOYEEDETAILS && PAGINATION PART ============================================================
  const viewdata = () => {
    setviewemployedata(false);
  };
  const [viewemployedata, setviewemployedata] = useState(false);
  const [employDetails, setEmploydetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalpages] = useState(0);
  const recordPerPage = 5;
  const totalRecords = 15;
  const pageRange = 5;

  useEffect(() => {
    get_employe_details();
  }, [0]);

  const handlePageChange = (pageNumber) => {
    get_employe_details(pageNumber);
    setCurrentPage(pageNumber);
  };
  const get_employe_details = async (page) => {
    try {
      var payload = {
        perpage: 5,
        page: page,
      };
      var data = {
        apiUrl: apiService.get_employe_details,
        payload: payload,
      };
      var resp = await postMethod(data);
      if (resp.status == true) {
        setEmploydetails(resp.data.data);
        setTotalpages(resp.data.total);
      } else {
        toast.error(resp.Message);
      }
    } catch (err) {}
  };

  //DELETEEMPLOYEEE ============================================================
  const deleteemploy = async (item) => {
    try {
      var deleteid = {
        id: item._id,
      };
      var data = {
        apiUrl: apiService.delete_employe_details,
        payload: deleteid,
      };
      var resp = await postMethod(data);
      if (resp.status == true) {
        toast.success(resp.Message);
        get_employe_details();
      } else {
        toast.error(resp.Message);
      }
    } catch (error) {}
  };

  //VIEWEMPOYEEE ===========================================================
  const [viewingemployedtails, setviewingemployedtails] = useState();
  const [viewingemployedtailsstatus, setviewingemployedtailsstatus] =
    useState(false);
  const viewemployedetails = (item) => {
    setviewingemployedtailsstatus(true);
    setviewingemployedtails(item);
  };
  const back = () => {
    setviewingemployedtailsstatus(false);
  };
  const backdetailspage = () => {
    setviewemployedata(true);
  };

  //EDITEMPLOYEE  =============================================================
  const [editingemployedtailsstatus, seteditingemployedtailsstatus] =
    useState(false);

  const editmployedetails = async (item) => {
    seteditingemployedtailsstatus(true);
    console.log(item, "editemployedtails.firstName");
    formValue.firstName = item.firstName;
    formValue.lastName = item.lastName;
    formValue.fathersName = item.fathersName;
    formValue.dateofBirth = item.dateofBirth;
    formValue.bloodGroup = item.bloodGroup;
    formValue.dateofJoin = item.dateofJoin;
    formValue.nationality = item.nationality;
    formValue.dateofLeave = item.dateofLeave;
    formValue.mail = item.mail;
    formValue.personalMail = item.personalMail;
    formValue.phone = item.phone;
    formValue.emergencyPhone = item.emergencyPhone;
    formValue.address1 = item.address1;
    formValue.address2 = item.address2;
    formValue.zip = item.zip;
    formValue.salary = item.salary;
    formValue.designation = item.designation;
    formValue.policy = item.policy;
    formValue.reasonofresign = item.reasonofresign;
    formValue.note = item.note;
    formValue.documentName = item.documentName;
    formValue.documentType = item.documentType;
    formValue.employeID = item.employeID;
    formValue.contract = item.contract;
    formValue.document = item.document;
    setSelectGender(item.gender);
    setSelectedCity(item.city);
    setSelectedCountry(item.country);
    setSelectedState(item.state);
    setSelectedDepartment(item.department);
    setWorkerstatus(item.status);
    formValue.id = item._id;
  };

  const edit_Employee = async () => {
    var editeddata = {
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      fathersName: formValue.fathersName,
      dateofBirth: formValue.dateofBirth,
      bloodGroup: formValue.bloodGroup,
      dateofJoin: formValue.dateofJoin,
      nationality: formValue.nationality,
      dateofLeave: formValue.dateofLeave,
      mail: formValue.mail,
      personalMail: formValue.personalMail,
      phone: formValue.phone,
      emergencyPhone: formValue.emergencyPhone,
      address1: formValue.address1,
      address2: formValue.address2,
      zip: formValue.zip,
      salary: formValue.salary,
      designation: formValue.designation,
      policy: formValue.policy,
      reasonofresign: formValue.reasonofresign,
      note: formValue.note,
      documentName: formValue.documentName,
      documentType: formValue.documentType,
      employeID: formValue.employeID,
      contract: formValue.contract,
      document: formValue.document,
      gender: SelectGender,
      country: selectedCountry,
      city: selectedCity,
      state: selectedState,
      department: selectedDepartment,
      id: formValue.id,
      status: Workerstatus,
    };
    // if(""){
    var data = {
      apiUrl: apiService.edit_employe_details,
      payload: editeddata,
    };
    var resp = await postMethod(data);
    if (resp.status == true) {
      toast.success(resp.Message);
      get_employe_details();
      setviewemployedata(false);
    } else {
    }
    // }else{
    //   toast.error("Invlid Fields");

    // }
  };
  const backemploydetailspage = () => {
    seteditingemployedtailsstatus(false);
  };

  return (
    <>
      {editingemployedtailsstatus == true ? (
        // EDINTING PAGE=====================================================
        <>
          <div className="row">
            <div className="col-md-9">
              <PageTitle title="Employee Data" />
            </div>
            <div className="col-md-3 d-block m-auto">
              <button
                className="btn btn-primary"
                onClick={backemploydetailspage}
              >
                back
              </button>
            </div>
          </div>

          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                <div className=" row my-3">
                  <div className="col-lg-6"></div>
                  <div className="col-lg-6 ">
                    <button
                      className={`btn ${
                        activeButton === "Personal"
                          ? "btn-secondary mx-3"
                          : "btn-light mx-3"
                      }`}
                      onClick={() => setActive("Personal")}
                    >
                      Personal
                    </button>
                    <button
                      className={`btn ${
                        activeButton === "Work"
                          ? "btn-secondary mx-3"
                          : "btn-light mx-3"
                      }`}
                      onClick={() => setActive("Work")}
                    >
                      Work
                    </button>

                    {/* <button
                      className={`btn ${
                        activeButton === "Contract"
                          ? "btn-secondary mx-3"
                          : "btn-light mx-3"
                      }`}
                      onClick={() => setActive("Contract")}
                    >
                      Contract
                    </button>

                    <button
                      className={`btn mx-3${
                        activeButton === "Document"
                          ? "btn-secondary mx-3"
                          : "btn-light mx-3"
                      }`}
                      onClick={() => setActive("Document")}
                    >
                      Document
                    </button> */}
                  </div>
                </div>
                {activeButton === "Personal" && (
                  <div>
                    <form className="form-sample">
                      <h5 className="my-5">General Information</h5>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              First Name
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                name="firstName"
                                value={firstName}
                                onChange={handleChange}
                              />
                              {firstNameErrref.current == true ? (
                                <p className="text-danger">
                                  {vallidation.firstName}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              Last Name
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                name="lastName"
                                value={lastName}
                                onChange={handleChange}
                              />{" "}
                              {lastNameErrref.current == true ? (
                                <p className="text-danger">
                                  {vallidation.lastName}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              Fathers Name
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                name="fathersName"
                                value={fathersName}
                                onChange={handleChange}
                              />
                              {fathersNameeErrref.current == true ? (
                                <p className="text-danger">
                                  {vallidation.fathersName}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              Gender
                            </label>
                            <div className="col-sm-9">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                onChange={(e) =>
                                  setSelectGender(e.target.value)
                                }
                              >
                                <option value={SelectGender}>
                                  {SelectGender}
                                </option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                              </select>
                              {GenderErrref.current == true ? (
                                <p className="text-danger">
                                  {vallidation.gender}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              Nationality
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                name="nationality"
                                value={nationality}
                                onChange={handleChange}
                              />
                              {nationalityErrref.current == true ? (
                                <p className="text-danger">
                                  {vallidation.nationality}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              Phone
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="number"
                                className="form-control"
                                name="phone"
                                value={phone}
                                onChange={handleChange}
                              />
                              {phoneErrref.current == true ? (
                                <p className="text-danger">
                                  {vallidation.phone}
                                </p>
                              ) : (
                                ""
                              )}{" "}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              Personal Mail
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                name="personalMail"
                                value={personalMail}
                                onChange={handleChange}
                              />
                              {personalMailErrref.current == true ? (
                                <p className="text-danger">
                                  {vallidation.personalMail}
                                </p>
                              ) : (
                                ""
                              )}{" "}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              Date Of Birth
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                name="dateofBirth"
                                value={dateofBirth}
                                onChange={handleChange}
                              />
                              {dateofBirthErrref.current == true ? (
                                <p className="text-danger">
                                  {vallidation.dateofBirth}
                                </p>
                              ) : (
                                ""
                              )}{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                      <h5 className="my-3">Address</h5>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              Address 1
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                name="address1"
                                value={address1}
                                onChange={handleChange}
                              />
                              {address1Errref.current == true ? (
                                <p className="text-danger">
                                  {vallidation.address1}
                                </p>
                              ) : (
                                ""
                              )}{" "}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              Address 2
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                name="address2"
                                value={address2}
                                onChange={handleChange}
                              />
                              {address2Errref.current == true ? (
                                <p className="text-danger">
                                  {vallidation.address2}
                                </p>
                              ) : (
                                ""
                              )}{" "}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              Zip
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                name="zip"
                                value={zip}
                                onChange={handleChange}
                              />
                              {zipErrref.current == true ? (
                                <p className="text-danger">{vallidation.zip}</p>
                              ) : (
                                ""
                              )}{" "}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              Country
                            </label>
                            <div className="col-sm-9">
                              <select
                                className="form-control"
                                name="Country"
                                value={selectedCountry}
                                onChange={(event) =>
                                  setSelectedCountry(event.target.value)
                                }
                              >
                                {countries.length > 0 ? (
                                  countries.map(({ isoCode, name }) => (
                                    <option
                                      className="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                                      value={isoCode}
                                      key={isoCode}
                                    >
                                      {name}
                                    </option>
                                  ))
                                ) : (
                                  <option className="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                                    No Countries Found!
                                  </option>
                                )}
                              </select>
                              {countryErrref.current == true ? (
                                <p className="text-danger">
                                  {vallidation.country}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              State
                            </label>
                            <div className="col-sm-9">
                              <select
                                className="form-control"
                                name="state"
                                value={selectedState}
                                onChange={(event) =>
                                  setSelectedState(event.target.value)
                                }
                              >
                                {states.length > 0 ? (
                                  states.map(({ isoCode, name }) => (
                                    <option
                                      className="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                                      value={isoCode}
                                      key={isoCode}
                                    >
                                      {name}
                                    </option>
                                  ))
                                ) : (
                                  <option className="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                                    No States Found!
                                  </option>
                                )}
                              </select>
                              {stateErrref.current == true ? (
                                <p className="text-danger">
                                  {vallidation.State}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              City
                            </label>
                            <div className="col-sm-9">
                              <select
                                className="form-control"
                                name="city"
                                value={selectedCity}
                                onChange={(event) =>
                                  setSelectedCity(event.target.value)
                                }
                              >
                                {cities.length > 0 ? (
                                  cities.map(({ isoCode, name }) => (
                                    <option
                                      className="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                                      value={isoCode}
                                      key={isoCode}
                                    >
                                      {name}
                                    </option>
                                  ))
                                ) : (
                                  <option className="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                                    No Cities Found!
                                  </option>
                                )}
                              </select>
                              {CityErrref.current == true ? (
                                <p className="text-danger">
                                  {vallidation.City}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Insert the rest of your input fields here */}
                    </form>
                  </div>
                )}

                {activeButton === "Work" && (
                  <div>
                    <form className="form-sample">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              Designation
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                name="designation"
                                value={designation}
                                onChange={handleChange}
                              />
                              {designationErrref.current == true ? (
                                <p className="text-danger">
                                  {vallidation.designation}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              E-Mail
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                name="mail"
                                value={mail}
                                onChange={handleChange}
                              />
                              {mailErrref.current == true ? (
                                <p className="text-danger">
                                  {vallidation.mail}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              Emergency Phone
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="number"
                                className="form-control"
                                name="emergencyPhone"
                                value={emergencyPhone}
                                onChange={handleChange}
                              />
                              {emergencyPhoneErrref.current == true ? (
                                <p className="text-danger">
                                  {vallidation.emergencyPhone}
                                </p>
                              ) : (
                                ""
                              )}{" "}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              Blood Group
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                name="bloodGroup"
                                value={bloodGroup}
                                onChange={handleChange}
                              />
                              {bloodGroupErrref.current == true ? (
                                <p className="text-danger">
                                  {vallidation.bloodGroup}
                                </p>
                              ) : (
                                ""
                              )}{" "}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              Employee Id
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                name="employeID"
                                value={employeID}
                                onChange={handleChange}
                              />
                              {employeIDErrref.current == true ? (
                                <p className="text-danger">
                                  {vallidation.employeID}
                                </p>
                              ) : (
                                ""
                              )}{" "}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              Salary
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                name="salary"
                                value={salary}
                                onChange={handleChange}
                              />
                              {salaryErrref.current == true ? (
                                <p className="text-danger">
                                  {vallidation.salary}
                                </p>
                              ) : (
                                ""
                              )}{" "}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              Date of Join
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                name="dateofJoin"
                                value={dateofJoin}
                                onChange={handleChange}
                              />
                              {dateofJoinErrref.current == true ? (
                                <p className="text-danger">
                                  {vallidation.dateofJoin}
                                </p>
                              ) : (
                                ""
                              )}{" "}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              Date of leave
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                name="dateofLeave"
                                value={dateofLeave}
                                onChange={handleChange}
                              />
                              {dateofLeaveErrref.current == true ? (
                                <p className="text-danger">
                                  {vallidation.dateofLeave}
                                </p>
                              ) : (
                                ""
                              )}{" "}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              Time of Policy
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                name="policy"
                                value={policy}
                                onChange={handleChange}
                              />
                              {policyErrref.current == true ? (
                                <p className="text-danger">
                                  {vallidation.policy}
                                </p>
                              ) : (
                                ""
                              )}{" "}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              Worker Status
                            </label>
                            <div className="col-sm-9">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                onChange={(e) =>
                                  setWorkerstatus(e.target.value)
                                }
                              >
                                <option value={Workerstatus}>
                                  {Workerstatus}
                                </option>
                                <option value="Active">Active</option>
                                <option value="DeActive">DeActive</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              Department
                            </label>
                            <div className="col-sm-9">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                onChange={handleDepartmentChange}
                              >
                                <option value={selectedDepartment}>
                                  {selectedDepartment}
                                </option>
                                {Department.map((dept) => (
                                  <option key={dept.key} value={dept.value}>
                                    {dept.label}
                                  </option>
                                ))}
                              </select>
                              {departmentErrref.current == true ? (
                                <p className="text-danger">
                                  {vallidation.department}
                                </p>
                              ) : (
                                ""
                              )}{" "}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              Note
                            </label>
                            <div className="col-sm-12">
                              <textarea
                                type="text"
                                className="form-control"
                                value={note}
                                name="note"
                                onChange={handleChange}
                              />
                              {noteErrref.current == true ? (
                                <p className="text-danger">
                                  {vallidation.note}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              Reason of Resignation
                            </label>
                            <div className="col-sm-12">
                              <textarea
                                type="text"
                                className="form-control"
                                value={reasonofresign}
                                name="reasonofresign"
                                onChange={handleChange}
                              />
                              {reasonofresignErrref.current == true ? (
                                <p className="text-danger">
                                  {vallidation.reasonofresign}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      
                      </div>
                    </form>
                    <button
                      type="button"
                      className="btn btn-primary"
                      onClick={edit_Employee}
                    >
                      Edit
                    </button>
                  </div>
                )}

                {/* {activeButton === "Contract" && (
                  <>
                    <div>
                      <form className="form-sample my-3">
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              Add New Contract
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                name="contract"
                                value={contract}
                                onChange={handleChange}
                              />
                              <small>Only docx,doc,docs,pdf,zip</small>
                            </div>
                            {contractErrref.current == true ? (
                              <p className="text-danger">
                                {vallidation.contract}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              Worker Status
                            </label>
                            <div className="col-sm-9">
                              <select
                                className="form-control"
                                id="exampleFormControlSelect1"
                                onChange={(e) =>
                                  setWorkerstatus(e.target.value)
                                }
                              >
                                <option value={Workerstatus}>
                                  {Workerstatus}
                                </option>
                                <option value="Active">Active</option>
                                <option value="DeActive">DeActive</option>
                              </select>
                              {GenderErrref.current == true ? (
                                <p className="text-danger">
                                  {vallidation.gender}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </>
                )}

                {activeButton === "Document" && (
                  <>
                    <div>
                      <form className="form-sample my-3">
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              Document Name
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                name="documentName"
                                value={documentName}
                                onChange={handleChange}
                              />
                              {documentNameErrref.current == true ? (
                                <p className="text-danger">
                                  {vallidation.documentName}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              Document Type
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                name="documentType"
                                value={documentType}
                                onChange={handleChange}
                              />
                              {documentTypeErrref.current == true ? (
                                <p className="text-danger">
                                  {vallidation.documentType}
                                </p>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row form-group">
                            <label className="col-sm-3 col-form-label">
                              Document
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                name="document"
                                value={document}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={edit_Employee}
                        >
                          Edit
                        </button>
                      </form>
                    </div>
                  </>
                )} */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {viewingemployedtailsstatus == true ? (
            //VIEWING PAGE=========================================================
            <div>
              <div className="row">
                <div className="col-md-9">
                  <PageTitle
                    title={viewingemployedtails.firstName + "details"}
                    className="my-3"
                  />
                </div>
                <div className="col-md-3 d-block m-auto">
                  <button className="btn btn-primary" onClick={back}>
                    back
                  </button>
                </div>
              </div>
              <div className="col-12 grid-margin">
                <div className="card">
                  <div className="card-body">
                    <div className=" row my-3">
                      <div className="col-lg-12">
                        <form className="form-sample">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  First Name
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="firstName"
                                    defaultValue={
                                      viewingemployedtails.firstName
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  Last Name
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    name="firstName"
                                    defaultValue={viewingemployedtails.lastName}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  Fathers Name
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={
                                      viewingemployedtails.fathersName
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  dateofBirth
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={
                                      viewingemployedtails.dateofBirth
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  blood Group
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={
                                      viewingemployedtails.bloodGroup
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  date of Join
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={
                                      viewingemployedtails.dateofJoin
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  date of leave
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={
                                      viewingemployedtails.dateofLeave
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  nationality
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={
                                      viewingemployedtails.nationality
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  mail
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={viewingemployedtails.mail}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  personal Mail
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={
                                      viewingemployedtails.personalMail
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  phone
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={viewingemployedtails.phone}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  emergency Phone
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={
                                      viewingemployedtails.emergencyPhone
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  address 1
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={viewingemployedtails.address1}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  addres 2
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={viewingemployedtails.address2}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  zip
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={viewingemployedtails.zip}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  salary
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={viewingemployedtails.salary}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  designation
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={
                                      viewingemployedtails.designation
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  policy
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={viewingemployedtails.policy}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  note
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={viewingemployedtails.note}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  reason of resign
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={
                                      viewingemployedtails.reasonofresign
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  documentName
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={
                                      viewingemployedtails.documentName
                                    }
                                  />
                                </div>
                              </div>
                            </div>{" "} */}
                            {/* <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  documentType
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={
                                      viewingemployedtails.documentType
                                    }
                                  />
                                </div>
                              </div>
                            </div>{" "} */}
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  employeID
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={
                                      viewingemployedtails.employeID
                                    }
                                  />
                                </div>
                              </div>
                            </div>{" "}
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  gender
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={viewingemployedtails.gender}
                                  />
                                </div>
                              </div>
                            </div>{" "}
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  country
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={viewingemployedtails.country}
                                  />
                                </div>
                              </div>
                            </div>{" "}
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  city
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={viewingemployedtails.city}
                                  />
                                </div>
                              </div>
                            </div>{" "}
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  state
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={viewingemployedtails.state}
                                  />
                                </div>
                              </div>
                            </div>{" "}
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  department
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={
                                      viewingemployedtails.department
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  contract
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={viewingemployedtails.contract}
                                  />
                                </div>
                              </div>
                            </div>{" "}
                            <div className="col-md-12">
                              <div className="row form-group">
                                <label className="col-sm-3 col-form-label">
                                  document
                                </label>
                                <div className="col-sm-9">
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue={viewingemployedtails.document}
                                  />
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div>
              {viewemployedata == false ? (
                // WHOLE EMPLOYEE DETAILS SHOW PAGE===========================================
                <>
                  <div className="row">
                    <div className="col-md-9">
                      <PageTitle title="Employee Data" className="my-3" />
                    </div>
                    <div className="col-md-3 d-block m-auto">
                      <button
                        className="btn btn-primary"
                        onClick={backdetailspage}
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  <div className="col-12 grid-margin">
                    <div className="card">
                      <div className="card-body">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th> S.No</th>
                              <th> Employe Name </th>
                              <th> Employe ID </th>
                              <th> Status</th>
                              <th>view</th>
                              <th> Action</th>
                              <th> Delete</th>
                            </tr>
                          </thead>

                          <tbody>
                            {employDetails && employDetails.length > 0 ? (
                              employDetails.map((item, i) => {
                                return (
                                  <tr key={item._id}>
                                    <td>{i + 1}</td>
                                    <td>{item.firstName}</td>
                                    <td>{item.employeID}</td>
                                    <td>{item.status}</td>
                                    <td
                                      onClick={() => viewemployedetails(item)}
                                    >
                                      {" "}
                                      <i className="fa fa-eye"></i>
                                    </td>
                                    <td onClick={() => editmployedetails(item)}>
                                      {" "}
                                      <i className="fa fa-edit"></i>
                                    </td>
                                    <td onClick={() => deleteemploy(item)}>
                                      {""}
                                      <i className="fa fa-trash"></i>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td className="colSpan5">No records</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                        <div className="row justify-content-end">
                          <Pagination
                            itemClass="page-item"
                            linkClass="page-link"
                            activePage={currentPage}
                            itemsCountPerPage={recordPerPage}
                            totalItemsCount={totalPage}
                            pageRangeDisplayed={pageRange}
                            onChange={handlePageChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                // EMPLY DETAILS FORM PAGE=====================================
                <>
                  <div className="row">
                    <div className="col-md-9">
                      <PageTitle title="Employee Data" />
                    </div>
                    <div className="col-md-3 d-block m-auto">
                      <button className="btn btn-primary" onClick={viewdata}>
                        view employedetails
                      </button>
                    </div>
                  </div>

                  <div className="col-12 grid-margin">
                    <div className="card">
                      <div className="card-body">
                        <div className=" row my-3">
                          <div className="col-lg-6">
                            <h4 className="card-title">Employee Data</h4>
                          </div>
                          <div className="col-lg-6 ">
                            <button
                              className={`btn ${
                                activeButton === "Personal"
                                  ? "btn-secondary mx-3"
                                  : "btn-light mx-3"
                              }`}
                              onClick={() => setActive("Personal")}
                            >
                              Personal
                            </button>
                            <button
                              className={`btn ${
                                activeButton === "Work"
                                  ? "btn-secondary mx-3"
                                  : "btn-light mx-3"
                              }`}
                              // onClick={() => setActive("Work")}
                            >
                              Work
                            </button>
                            {/* 
                            <button
                              className={`btn ${
                                activeButton === "Contract"
                                  ? "btn-secondary mx-3"
                                  : "btn-light mx-3"
                              }`}
                              onClick={() => setActive("Contract")}
                            >
                              Contract
                            </button>

                            <button
                              className={`btn mx-3${
                                activeButton === "Document"
                                  ? "btn-secondary mx-3"
                                  : "btn-light mx-3"
                              }`}
                              onClick={() => setActive("Document")}
                            >
                              Document
                            </button> */}
                          </div>
                        </div>
                        {activeButton === "Personal" && (
                          <div>
                            <form className="form-sample">
                              <h5 className="my-5">General Information</h5>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      First Name
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="firstName"
                                        value={firstName}
                                        onChange={handleChange}
                                      />
                                      {firstNameErrref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.firstName}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      Last Name
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="lastName"
                                        value={lastName}
                                        onChange={handleChange}
                                      />{" "}
                                      {lastNameErrref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.lastName}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      Fathers Name
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="fathersName"
                                        value={fathersName}
                                        onChange={handleChange}
                                      />
                                      {fathersNameeErrref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.fathersName}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      Gender
                                    </label>
                                    <div className="col-sm-9">
                                      <select
                                        className="form-control"
                                        id="exampleFormControlSelect1"
                                        onChange={(e) =>
                                          setSelectGender(e.target.value)
                                        }
                                      >
                                        <option value="Choose Gender">
                                          Choose Gender
                                        </option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                      </select>
                                      {GenderErrref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.gender}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      Nationality
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="nationality"
                                        value={nationality}
                                        onChange={handleChange}
                                      />
                                      {nationalityErrref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.nationality}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      Phone
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="phone"
                                        value={phone}
                                        onChange={handleChange}
                                      />
                                      {phoneErrref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.phone}
                                        </p>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      Personal Mail
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="personalMail"
                                        value={personalMail}
                                        onChange={handleChange}
                                      />
                                      {personalMailErrref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.personalMail}
                                        </p>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      Date Of Birth
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="dateofBirth"
                                        value={dateofBirth}
                                        onChange={handleChange}
                                      />
                                      {dateofBirthErrref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.dateofBirth}
                                        </p>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <h5 className="my-3">Address</h5>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      Address 1
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="address1"
                                        value={address1}
                                        onChange={handleChange}
                                      />
                                      {address1Errref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.address1}
                                        </p>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      Address 2
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="address2"
                                        value={address2}
                                        onChange={handleChange}
                                      />
                                      {address2Errref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.address2}
                                        </p>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      Zip
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="zip"
                                        value={zip}
                                        onChange={handleChange}
                                      />
                                      {zipErrref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.zip}
                                        </p>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      Country
                                    </label>
                                    <div className="col-sm-9">
                                      <select
                                        className="form-control"
                                        name="Country"
                                        value={selectedCountry}
                                        onChange={(event) =>
                                          setSelectedCountry(event.target.value)
                                        }
                                      >
                                        {countries.length > 0 ? (
                                          countries.map(({ isoCode, name }) => (
                                            <option
                                              className="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                                              value={isoCode}
                                              key={isoCode}
                                            >
                                              {name}
                                            </option>
                                          ))
                                        ) : (
                                          <option className="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                                            No Countries Found!
                                          </option>
                                        )}
                                      </select>
                                      {countryErrref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.country}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      State
                                    </label>
                                    <div className="col-sm-9">
                                      <select
                                        className="form-control"
                                        name="state"
                                        value={selectedState}
                                        onChange={(event) =>
                                          setSelectedState(event.target.value)
                                        }
                                      >
                                        {states.length > 0 ? (
                                          states.map(({ isoCode, name }) => (
                                            <option
                                              className="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                                              value={isoCode}
                                              key={isoCode}
                                            >
                                              {name}
                                            </option>
                                          ))
                                        ) : (
                                          <option className="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                                            No States Found!
                                          </option>
                                        )}
                                      </select>
                                      {stateErrref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.State}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      City
                                    </label>
                                    <div className="col-sm-9">
                                      <select
                                        className="form-control"
                                        name="city"
                                        value={selectedCity}
                                        onChange={(event) =>
                                          setSelectedCity(event.target.value)
                                        }
                                      >
                                        {cities.length > 0 ? (
                                          cities.map(({ isoCode, name }) => (
                                            <option
                                              className="scrollbar-custom flex max-h-48 flex-col overflow-y-auto"
                                              value={isoCode}
                                              key={isoCode}
                                            >
                                              {name}
                                            </option>
                                          ))
                                        ) : (
                                          <option className="scrollbar-custom flex max-h-48 flex-col overflow-y-auto">
                                            No Cities Found!
                                          </option>
                                        )}
                                      </select>
                                      {CityErrref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.City}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* Insert the rest of your input fields here */}
                            </form>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={next1}
                            >
                              Next
                            </button>
                          </div>
                        )}

                        {activeButton === "Work" && (
                          <div>
                            <form className="form-sample">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      Designation
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="designation"
                                        value={designation}
                                        onChange={handleChange}
                                      />
                                      {designationErrref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.designation}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      E-Mail
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="mail"
                                        value={mail}
                                        onChange={handleChange}
                                      />
                                      {mailErrref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.mail}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      Emergency Phone
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="number"
                                        className="form-control"
                                        name="emergencyPhone"
                                        value={emergencyPhone}
                                        onChange={handleChange}
                                      />
                                      {emergencyPhoneErrref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.emergencyPhone}
                                        </p>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      Blood Group
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="bloodGroup"
                                        value={bloodGroup}
                                        onChange={handleChange}
                                      />
                                      {bloodGroupErrref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.bloodGroup}
                                        </p>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      Employee Id
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="employeID"
                                        value={employeID}
                                        onChange={handleChange}
                                      />
                                      {employeIDErrref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.employeID}
                                        </p>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      Salary
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="salary"
                                        value={salary}
                                        onChange={handleChange}
                                      />
                                      {salaryErrref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.salary}
                                        </p>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      Date of Join
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="dateofJoin"
                                        value={dateofJoin}
                                        onChange={handleChange}
                                      />
                                      {dateofJoinErrref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.dateofJoin}
                                        </p>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      Date of leave
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="dateofLeave"
                                        value={dateofLeave}
                                        onChange={handleChange}
                                      />
                                      {dateofLeaveErrref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.dateofLeave}
                                        </p>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      Time of Policy
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="policy"
                                        value={policy}
                                        onChange={handleChange}
                                      />
                                      {policyErrref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.policy}
                                        </p>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      Department
                                    </label>
                                    <div className="col-sm-9">
                                      <select
                                        className="form-control"
                                        id="exampleFormControlSelect1"
                                        value={selectedDepartment}
                                        onChange={handleDepartmentChange}
                                      >
                                        <option value="">
                                          Select Department
                                        </option>
                                        {Department.map((dept) => (
                                          <option
                                            key={dept.key}
                                            value={dept.value}
                                          >
                                            {dept.label}
                                          </option>
                                        ))}
                                      </select>
                                      {departmentErrref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.department}
                                        </p>
                                      ) : (
                                        ""
                                      )}{" "}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      Note
                                    </label>
                                    <div className="col-sm-12">
                                      <textarea
                                        type="text"
                                        className="form-control"
                                        value={note}
                                        name="note"
                                        onChange={handleChange}
                                      />
                                      {noteErrref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.note}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      Reason of Resignation
                                    </label>
                                    <div className="col-sm-12">
                                      <textarea
                                        type="text"
                                        className="form-control"
                                        value={reasonofresign}
                                        name="reasonofresign"
                                        onChange={handleChange}
                                      />
                                      {reasonofresignErrref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.reasonofresign}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                            <button
                              type="button"
                              className="btn btn-primary"
                              onClick={next2}
                            >
                              Save
                            </button>
                          </div>
                        )}

                        {/* {activeButton === "Contract" && (
                          <>
                            <div>
                              <form className="form-sample my-3">
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      Add New Contract
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="contract"
                                        value={contract}
                                        onChange={handleChange}
                                      />
                                      <small>Only docx,doc,docs,pdf,zip</small>
                                    </div>
                                    {contractErrref.current == true ? (
                                      <p className="text-danger">
                                        {vallidation.contract}
                                      </p>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </form>
                            </div>
                          </>
                        )}

                        {activeButton === "Document" && (
                          <>
                            <div>
                              <form className="form-sample my-3">
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      Document Name
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="documentName"
                                        value={documentName}
                                        onChange={handleChange}
                                      />
                                      {documentNameErrref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.documentName}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      Document Type
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="documentType"
                                        value={documentType}
                                        onChange={handleChange}
                                      />
                                      {documentTypeErrref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.documentType}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="row form-group">
                                    <label className="col-sm-3 col-form-label">
                                      Document
                                    </label>
                                    <div className="col-sm-9">
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="document"
                                        value={document}
                                        onChange={handleChange}
                                      />
                                      {documentErrref.current == true ? (
                                        <p className="text-danger">
                                          {vallidation.document}
                                        </p>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <button
                                  type="button"
                                  className="btn btn-primary"
                                  onClick={next4}
                                >
                                  Save
                                </button>
                              </form>
                            </div>
                          </>
                        )} */}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
}
