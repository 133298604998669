
import React, { useEffect } from "react";

import useState from 'react-usestateref';


import Pagination from "react-js-pagination";
import apiService from "../core/service/detail";
import {getMethod} from "../core/service/common.api";
import {postMethod } from "../core/service/common.api";
// import { PdfImage } from '../images/PDF_file_icon.svg.png'
import PdfImage from "../images/PDF_file_icon.svg.png";
import moment from "moment";

function Carrer() {



  const [currentPage, setCurrentPage] =useState(1);
  const [getP2POrders, setgetAllp2pOrders, getP2POrdersref] = useState([]);
  const [totalPage, setTotalpages] =useState(0);


  const recordPerPage = 5;
  // total number of the records
  const totalRecords = 15;
  // range of pages in paginator
  const pageRange = 5;


  const handlePageChange = pageNumber => {
    getContacts(pageNumber)
    setCurrentPage(pageNumber);
    // setchoosepage()
  }

  useEffect( () => {
     getContacts();
  }, []);

  const getContacts = async (page) => {
    try {
        var payload = {
          perpage: 5,
          page : page,
          search : 'btc'
        }
        var data = {
        apiUrl: apiService.careerdata,
        payload : payload
      };
      var resp = await postMethod(data);
      if (resp.status) {
        console.log(resp.data.data,'resp.data.data')
        setgetAllp2pOrders(resp.data.data);
        setTotalpages(resp.data.total);
      }
    } catch (error) {

    }
  }

  const enquiry = async  (e) => {

    try {

      var payload = {
        _id : e._id,
        status : "read"
      }
      var data = {
        apiUrl: apiService.changecareerstatus,
        payload : payload
      };

      var resp = await postMethod(data);
      if(resp.status){
        getContacts();
      }else{
        
      }
      
    } catch (error) {
      console.log('=-=-=-=-=-=-=-=-',error,'=-=-=-=-=-error-=-=')
    }

  }

  return (
  
    <div> Career

    <table class="table table-striped">
       <thead>
        <tr>
          <th> S.No</th>
          <th> Profile Name </th>
          <th> Email</th>
          <th> Mobile Number</th>
          <th> Language </th>
          <th> Experience </th>
          <th> createdAt </th>
          <th> Resume </th>
          <th> Status </th>

        </tr>
       </thead>

          <tbody>
            {
               getP2POrdersref.current && getP2POrdersref.current.map((orders, i) => {

                  return(
                    <tr>
                    <td>
                        {i + 1 }
                    </td>
                    <td>
                        {orders.userName }
                    </td>
                    <td>
                        {orders.email }
                    </td>
                    <td>
                        {orders.mobileNumber }
                    </td>
                    <td>
                        {orders.language }
                    </td>
                    <td>
                        {orders.experience }
                    </td>
                    <td>
                    {moment(orders.createdAt).format(("lll"))}
                    </td>
                    <td>
                      <img style= {{"width":"20px"}} src= {PdfImage} /> 
                      <a href={orders.resume} target="_blank">  View </a>
                    </td>
                    <td>
                        { orders.status == "" ? <p style={{color: "green"}} onClick={()=>enquiry(orders) } > Open </p> :  <p style={{color: "red"}}> Closed </p>  }

                    </td>
                  </tr>
                  )
                })
             }
           
          </tbody>

    </table>

    <Pagination
        itemClass="page-item" // add it for bootstrap 4
        linkClass="page-link" // add it for bootstrap 4
        activePage={currentPage}
        itemsCountPerPage={recordPerPage}
        totalItemsCount={totalPage}
        pageRangeDisplayed={pageRange}
        onChange={handlePageChange}
      />
    </div>

  )
}



export default Carrer